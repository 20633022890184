import React from "react";
import classes from './UserDropdown.module.css';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
    PersonRounded,
    ExitToAppRounded,
    CodeRounded,
    GroupAddRounded,
    CloudRounded,
    PersonOutlineRounded,
    SettingsRounded
} from '@material-ui/icons';
import * as Configs from '../../../../config';
import {permissions} from '../../../../store/permissions';

import {Dropdown} from "react-bootstrap";

const UserDropdown = props => {
    const userInfo = useSelector(state => {
        // console.log(state.user.detail)
        return state.user.detail;
    });

    const getName = userInfo => {
        if (userInfo) {
            if (userInfo.user.first_name) {
                return userInfo.user.first_name + " " + userInfo.user.last_name;
            } else {
                return userInfo.user.username;
            }
        }
        return '';
    }
    const getUserIcon = userInfo => {
        let user = (<PersonRounded className={classes.UserIcon}/>);
        if (userInfo && userInfo.user.first_name && userInfo.user.first_name.length > 0 && userInfo.user.last_name && userInfo.user.last_name.length > 0) {
            user = (userInfo.user.first_name[0] + userInfo.user.last_name[0]).toUpperCase();
        }
        return <div className={classes.DropdownText}>{user}</div>
    }
    let tenantName = (userInfo && userInfo.tenant.name) || '';
    let userIcon = getUserIcon(userInfo)
    let name = getName(userInfo);
    const userRoleUuid = (userInfo && userInfo.user.role) || null;
    const manageConnections = (userRoleUuid && permissions[userRoleUuid] && permissions[userRoleUuid].settings.account.overall);
    const manageUsers = (userRoleUuid && permissions[userRoleUuid] && permissions[userRoleUuid].settings.user.overall);
    const accountSettings = (userRoleUuid && permissions[userRoleUuid] && (permissions[userRoleUuid].settings.account_configuration.overall || permissions[userRoleUuid].settings.account_configuration.attribute_mapping.overall));

    let manageConnectionsView = null;
    if (manageConnections) {
        manageConnectionsView = (
            <li className={classes.BorderTop}>
                <a href={Configs.AngularWebURL + "#/!/manage_accounts"}>
                    <CloudRounded className={classes.FontImage}/> Manage Connections
                </a>
            </li>
        );
    }

    let manageUsersView = null;
    if (manageUsers) {
        manageUsersView = (
            <li>
                <a href={Configs.AngularWebURL + "#/!/manage_users/list"}>
                    <GroupAddRounded className={classes.FontImage}/> Manage
                    Users
                </a>
            </li>

        );
    }

    let accountSettingsView = null;
    if (accountSettings) {
        accountSettingsView = (
            <li>
                <a href={Configs.AngularWebURL + "#/!/account_settings/attribute_mapping/aws"}> <SettingsRounded
                    className={classes.FontImage}/> Account Settings </a>
            </li>

        );
    }


    return (<Dropdown>
        <Dropdown.Toggle as="div" id="dropdown-basic" className={classes.UserDropdown}>
            {userIcon}
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight="true" className={classes.Dropdown}>
            <ul className={[classes.DropdownMenu, classes.UserDropdownMenu].join(' ')}>
                <div className={classes.UserInfo}>
                    <div style={{fontSize: '13px'}}>
                        <PersonRounded className={classes.FontImage}/>
                        <span style={{marginLeft: '5px'}}>{name}</span>
                    </div>
                    <div className={classes.TenantName}> {tenantName}</div>
                </div>
                {manageConnectionsView}
                {manageUsersView}
                <li>
                    <a href={Configs.AngularWebURL + "/api/index.html"} target="_blank" rel="noopener noreferrer">
                        <CodeRounded className={classes.FontImage}/> Yotascale API
                    </a>
                </li>
                <li className={classes.BorderTop}>
                    <a href={Configs.AngularWebURL + "#/!/user_settings/change_password"}>
                        <PersonOutlineRounded className={classes.FontImage}/> My
                        Settings
                    </a>
                </li>
                {accountSettingsView}
                <li className={classes.BorderTop}>
                    <Link to="/logout"> <ExitToAppRounded className={classes.FontImage}/> Log Out
                    </Link>
                </li>
            </ul>
        </Dropdown.Menu>
    </Dropdown>);
};

export default UserDropdown;
