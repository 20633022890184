import React from "react";
import classes from './Layout.module.css';
import Toolbar from '../Navigation/Navigation';
import {useSelector} from 'react-redux';
import Loading from "../../UI/Loading/Loading";


const Layout = props => {


    const userLoading = useSelector(state => {
        return !state.user.loading;
    });

    let view = <Loading msg="Fetching Data..."/>;

    if (userLoading) {
         view = <main>
            {props.children}
        </main>
    }
    // console.log(['Layout'])
    return (
        <div className={classes.Layout}>
            <Toolbar/>
            {view}
        </div>
    );
};


export default Layout;