import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from '@material-ui/core/styles';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {TableCellWhite} from './Table'

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));
const BCListTableHeader = props => {
    const classes = useStyles();
    const headCells = [
        {id: 'complete_path', label: props.context_name, width: {width: '40%'}},
        {id: 'priority', label: 'Priority ', width: {width: '10%'}},
        {id: 'rules', label: 'Allocation Rules', width: {width: '40%'}}
    ];
    const {order, orderBy, onRequestSort} = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCellWhite key={headCell.id}
                                    sortDirection={orderBy === headCell.id ? order.id : false} style={headCell.width}>

                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCellWhite>
                ))}
                <TableCellWhite/>
            </TableRow>
        </TableHead>
    );
}

BCListTableHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired
};

export default BCListTableHeader;