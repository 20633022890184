export const isSupportUser = email => (/^support\+[+A-Za-z0-9._%-]+@yotascale\.com$/i).test(email);
export const isNumber = number => (/^\d+$/).test(number);
export const strToInteger = numberStr => {
    const num = parseInt(numberStr.toString(), 10);
    if (!isNaN(num) && isNumber(num)) {
        return parseInt(numberStr.toString(), 10);
    }
    return '';
}

export const eventFormatting = event => {
    return {
        id: event.id,
        value: `Widget: ${event.type} changed by User Name: ${event.user}, for Tenant: ${event.tenant_name}, and action: ${event.action} at: ${event.timestamp}`
    }
}