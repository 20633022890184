import React, {useState, useEffect, useCallback} from "react";
import * as actions from '../../../../store/actions';
import {useDispatch, useSelector} from 'react-redux';

import {Grid} from "@material-ui/core";
import {TabPanel, TabContext, ToggleButtonGroup} from '@material-ui/lab';
import {ToggleButtonPrimary} from "../../../../components/UI/MaterialUI/ToggleButton";
import {CloudUploadRounded, Add, AccountTreeRounded, GetAppRounded} from '@material-ui/icons'
import {ButtonWhite} from '../../../../components/UI/MaterialUI/Button';

import Aux from "../../../../hoc/Aux/Aux";
import Loading from "../../../../components/UI/Loading/Loading";
import ContextList from '../../../../components/Application/AccountSettings/Contexts/ContextList/ContextList';
import ContainerBox from "../../../../components/UI/Container/ContainerBox/ContainerBox";
import DeleteDialog
    from "../../../../components/Application/AccountSettings/Contexts/ContextActions/DeleteDialog/DeleteDialog";

const AddEditPanel = React.lazy(() => {
    return import("../../../../components/Application/AccountSettings/Contexts/ContextActions/AddEditPanel/AddEditPanel");
});
const ContextHierarchy = React.lazy(() => {
    return import('../../../../components/Application/AccountSettings/Contexts/ContextHierarchy/ContextHierarchy');
});
const UploadCSV = React.lazy(() => {
    return import('../../../../components/Application/AccountSettings/Contexts/UploadCSV/UploadCSV');
});
const ContextMapping = React.lazy(() => {
    return import('../../../../components/Application/AccountSettings/Contexts/ContextMapping/ContextMapping');
});


const Context = props => {

    const [showUploadBCPopup, setShowUploadBCPopup] = useState(false);
    const [showMappingPopup, setShowMappingPopup] = useState(false);
    const [showAddEditPanel, setShowAddEditPanel] = useState(false);
    const [showUpDeleteDialog, setShowUpDeleteDialog] = useState(false);
    const [sidePanelType, setSidePanelType] = useState(false);
    const [selectedBC, setSelectedBC] = useState(null);
    const [loadingView, setLoadingView] = useState(null);
    const closeAllPopous = () => {
        // console.log(["Closing all popups"]);
        setShowUploadBCPopup(false);
        setShowMappingPopup(false);
        setShowUpDeleteDialog(false);
        setShowAddEditPanel(false);
    }

    const handleUploadBCShow = () => {
        setShowUploadBCPopup(true);
    };
    const handleMappingShow = () => {
        setShowMappingPopup(true);
    };
    const handleShowAddEditPanel = (sidepanel_type) => {
        if (sidepanel_type) {
            setSidePanelType(sidepanel_type);
        }
        setShowAddEditPanel(true);
    };
    const handleSelectedBC = (event) => {
        setSelectedBC(event);
    };

    const handleShowHideDeletePanel = (event) => {
        setShowUpDeleteDialog(true);
    }

    const context = useSelector(state => {
        return state.context;
    });
    const contextMapping = useSelector(state => {
        return (state.context.contextMapping) || []
    });
    const outerLoading = useSelector(state => {
        return state.auth.loading
    });

    const dispatch = useDispatch();
    const loadContexts = useCallback(() => dispatch(actions.getContexts()), [dispatch]);

    const [value, setValue] = useState('1');
    const handleTabChange = (event, newVal) => {
        if (newVal) {
            setValue(newVal);
        }
    }
    const handleNewBC = () => {
        setSelectedBC(null);
        handleShowAddEditPanel("ADD_NEW_NODE");
    }

    useEffect(() => {
        loadContexts();
    }, [loadContexts]);


    useEffect(() => {
        closeAllPopous();
        if (outerLoading === false && context.loading === true) {
            let msg = "Loading Business Context Data...";
            if (context.loading_msg) {
                msg = context.loading_msg;
            }
            setLoadingView(<Loading msg={msg}/>);
        } else {
            setLoadingView(null);
        }
    }, [outerLoading, context.loading, context.loading_msg])

    let uploadCSVView = showUploadBCPopup ?
        <UploadCSV onHide={closeAllPopous} showUploadDialog={showUploadBCPopup}/> : null;
    let contextMappingView = showMappingPopup ?
        <ContextMapping onHide={closeAllPopous} showContextMappingDialog={showMappingPopup}
                        mappingData={contextMapping}/> : null;
    let addEditPanelView = showAddEditPanel ?
        <AddEditPanel onHide={closeAllPopous} mappingLength={contextMapping.length} selectedBC={selectedBC}
                      showAddEditPanel={showAddEditPanel}
                      sidePanelType={sidePanelType}/> : null;
    let deletePanelView = showUpDeleteDialog ?
        <DeleteDialog onHide={closeAllPopous} showUpDeleteDialogProp={showUpDeleteDialog}
                      selectedBC={selectedBC}/> : null;

    // console.log(["Container Context"])
    return (
        <Aux>
            {loadingView}
            <Grid container justify="center">
                <Grid item xs={12}>
                    {uploadCSVView}
                    {contextMappingView}
                    {addEditPanelView}
                    {deletePanelView}

                    <Grid container justify="flex-end">
                        <Grid item xs={5} style={{padding: "10px 0"}}>
                            <h4>
                                Current Business Context Rules
                            </h4>
                        </Grid>
                        <Grid item xs={7} style={{textAlign: "right"}}>
                            <ButtonWhite variant="outlined" style={{marginRight: "5px"}} onClick={handleMappingShow}>
                                <span> <AccountTreeRounded fontSize="small"
                                                           style={{marginTop: "-5px", marginRight: "5px"}}/> Edit Context Categories</span>
                            </ButtonWhite>
                            <ButtonWhite variant="outlined" onClick={handleUploadBCShow}>
                                <span>
                                    <CloudUploadRounded fontSize="small"
                                                        style={{
                                                            marginTop: "-5px",
                                                            marginRight: "5px"
                                                        }}/> Upload CSV
                                </span>
                            </ButtonWhite>

                        </Grid>
                        <Grid item xs={12}>
                            <p>
                                The cost allocation follows a hierarchy where each step of the priority acts like a
                                "sieve". This means any resources allocated by business context priority 1, will not be
                                allocated to business context priority 2 even if it matches the rule. Make sure your
                                most important contexts are at the top of the priority list, and pay special attention
                                to your "unallocated" and "shared" resources.
                            </p>
                        </Grid>
                    </Grid>

                    <ContainerBox>
                        <Grid container justify="flex-end">
                            <ButtonWhite variant="outlined" onClick={() => handleNewBC()} style={{marginRight: "5px"}}>
                                <span> <Add fontSize="small" style={{marginTop: "-5px", marginRight: "5px"}}/> Add Business Context </span>
                            </ButtonWhite>

                            <ToggleButtonGroup
                                value={value}
                                exclusive
                                onChange={handleTabChange}
                                aria-label="text alignment">
                                <ToggleButtonPrimary value="2" aria-label="hierarchy view">
                                    Hierarchy
                                </ToggleButtonPrimary>
                                <ToggleButtonPrimary value="1" aria-label="list view">
                                    List
                                </ToggleButtonPrimary>
                            </ToggleButtonGroup>

                            <ButtonWhite variant="outlined" disabled style={{marginLeft: "5px"}}>
                                <span> <GetAppRounded fontSize="small"/> </span>
                            </ButtonWhite>

                        </Grid>
                        <TabContext value={value}>
                            <TabPanel value="1">
                                <ContextList context={context.contextData} contextMapping={contextMapping}
                                             handleBC={handleSelectedBC}
                                             handleSidePanel={handleShowAddEditPanel}
                                             handleDeletePanel={handleShowHideDeletePanel}/>
                            </TabPanel>
                            <TabPanel value="2">
                                <ContextHierarchy selectedBC={selectedBC} handleBC={handleSelectedBC}
                                                  contextData={context.contextData}
                                                  handleSidePanel={handleShowAddEditPanel}
                                                  handleDeletePanel={handleShowHideDeletePanel}/>
                            </TabPanel>
                        </TabContext>
                    </ContainerBox>
                </Grid>
            </Grid>
        </Aux>
    );
};

export default Context;
