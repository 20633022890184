import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    error: null,
    loading: false,
    loading_msg: null,
    successMsg: null,
    distinct_dashboard: [],
    global_widget: [],
    perspectiveWidget: [],
    eventLogs: []
};

const requestWidget = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
        loading_msg: action.msg,
        successMsg: null,
        distinct_dashboard: [],
        global_widget: [],
        perspectiveWidget: []

    });
};
const successWidget = (state, action) => {
    return updateObject(state, {
        error: false,
        loading: false,
        loading_msg: null,
        successMsg: action.successMsg,
        distinctDashboard: action.widgetData.distinct_dashboards,
        global_widget: action.widgetData.global_widgets,
        perspectiveWidget: action.widgetData.perspective_widget
    });
};
const failWidget = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        loading_msg: null,
        successMsg: null,
        distinctDashboard: [],
        global_widget: [],
        perspectiveWidget: []
    });
};

const loadingStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        loading_msg: action.msg
    });
};

const stopLoading = (state, action) => {
    return updateObject(state, {
        loading: false,
        loading_msg: null
    });
};

const eventLogsRequest = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
        successMsg: null,
        eventLogs: []
    })
}

const eventLogsLoadingStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        loading_msg: action.msg
    })
}

const eventLogsLoadingSuccess = (state, action) => {
    return updateObject(state, {
        error: false,
        successMsg: action.successMsg,
        eventLogs: action.eventLogs.data
    });
}

const eventLogsLoadingFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        successMsg: null,
        eventLogs: []
    })
}

const eventLogsLoadingStop = (state, action) => {
    return updateObject(state, {})
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.WIDGET_DATA_LOAD_START:
            return requestWidget(state, action);
        case actionTypes.WIDGET_DATA_LIST_LOAD_SUCCESS:
            return successWidget(state, action);
        case actionTypes.WIDGET_DATA_LOAD_FAIL:
            return failWidget(state, action);
        case actionTypes.WIDGET_DATA_LOADING_START:
            return loadingStart(state, action);
        case actionTypes.WIDGET_STOP_LOADING:
            return stopLoading(state, action);
        case actionTypes.WIDGET_EVENT_LOGS_LOAD_START:
            return eventLogsRequest(state, action);
        case actionTypes.WIDGET_EVENT_LOGS_LOADING_START:
            return eventLogsLoadingStart(state, action)
        case actionTypes.WIDGET_EVENT_LOGS_LOAD_SUCCESS:
            return eventLogsLoadingSuccess(state, action)
        case actionTypes.WIDGET_EVENT_LOGS_LOAD_FAIL:
            return eventLogsLoadingFail(state, action)
        case actionTypes.WIDGET_EVENT_LOGS_STOP_LOADING:
            return eventLogsLoadingStop(state, action)
        default:
            return state;
    }
};

export default reducer;
