import axios from 'axios';
import * as actionTypes from './actionTypes';
import {NotificationManager} from 'react-notifications';
import {errorHandler} from '../utility';


export const requestWidgetAction = () => {
    return {
        type: actionTypes.WIDGET_DATA_LOAD_START
    };
};

export const requestLoading = (msg) => {
    return {
        type: actionTypes.WIDGET_DATA_LOADING_START,
        msg: msg
    };
};

export const failWidgetAction = (error) => {
    return {
        type: actionTypes.WIDGET_DATA_LOAD_FAIL,
        error: error
    };
};

export const successWidgetAction = (data) => {
    return {
        type: actionTypes.WIDGET_DATA_LIST_LOAD_SUCCESS,
        widgetData: data
    };
};

export const stopLoading = () => {
    return {
        type: actionTypes.WIDGET_STOP_LOADING
    };
};

export const requestEventLogsAction = () => {
    return {
        type: actionTypes.WIDGET_EVENT_LOGS_LOAD_START
    }
}

export const requestEventLogsLoading = (msg) => {
    return {
        type: actionTypes.WIDGET_EVENT_LOGS_LOADING_START,
        msg: msg
    }
}

export const eventLogsFailAction = (error) => {
    return {
        type: actionTypes.WIDGET_EVENT_LOGS_LOAD_FAIL,
        error: error
    }
}

export const eventLogsSuccessAction = (data) => {
    return {
        type: actionTypes.WIDGET_EVENT_LOGS_LOAD_SUCCESS,
        eventLogs: data
    }
}

export const eventLogsStopLoading = () => {
    return {
        type: actionTypes.WIDGET_EVENT_LOGS_STOP_LOADING
    }
}


let widget;
export const getWidgets = (widget_type) => {
    widget = widget_type;
    return dispatch => {
        dispatch(requestWidgetAction());
        axios.get('widgets_shuffle/' + widget_type, {
            withCredentials: true
        })
            .then(response => {
                // console.log(response.data.result);
                dispatch(successWidgetAction(response.data.result))
            })
            .catch(err => {
                // console.log(err);
                const msg = errorHandler(dispatch, err);
                NotificationManager.error(msg, '', 10000);
                dispatch(failWidgetAction(msg));
            })
    };
};

export const deleteWidgets = (id) => {
    console.log("delete widget",id);
    return dispatch => {
        const formData = {'ids': []};
        formData['ids'] = id;
        dispatch(requestLoading("Deleting Widget..."));
        axios.patch('/widgets_shuffle/delete',formData , {
            withCredentials: true
        })
            .then(response => {
                dispatch(getWidgets(widget))
                NotificationManager.success('Widget has been deleted successfully.', 'Deleted!', 10000);
            })
            .catch(err => {
                const msg = errorHandler(dispatch, err);
                NotificationManager.error(msg, '', 10000);
                dispatch(stopLoading());
            });
    };
};

export const addwidget = (widgets) => {
    console.log("add widget",widgets);
    return dispatch => {
        const formData = {'widgets': []};
        formData['widgets'] = widgets;
        dispatch(requestLoading("Add Widget..."));
        axios.post('/widgets_shuffle/add',formData , {
            withCredentials: true
        })
            .then(response => {
                dispatch(getWidgets(widget))
                NotificationManager.success('Widget has been added successfully.', 'Added!', 10000);
            })
            .catch(err => {
                const msg = errorHandler(dispatch, err);
                NotificationManager.error(msg, '', 10000);
                dispatch(stopLoading());
            });
    };
};

export const updateWidget = (widget) => {
    console.log("Update Widget: ", widget)
    return dispatch => {
        const formData = widget;
        dispatch(requestLoading("Update Widget..."));
        axios.put(`/widgets_shuffle/update/${widget.pw_identifier}`, formData, {
            withCredentials: true
        }).then(response => {
            NotificationManager.success('Widget updated Successfully.', 'Updated!', 10000);
        }).catch(err => {
            const msg = errorHandler(dispatch, err);
            NotificationManager.error(msg, '', 10000);
            dispatch(stopLoading());
            dispatch(getWidgets(widget.widget_dashboard))
        })
    }
}

export const getWidgetsEventLogs = () => {
    return dispatch => {
        dispatch(requestEventLogsAction());
        axios.get('widgets_shuffle/event_logs', {
            withCredentials: true
        })
            .then(response => {
                dispatch(eventLogsSuccessAction(response.data))
            })
            .catch(err => {
                const msg = errorHandler(dispatch, err);
                NotificationManager.error(msg, '', 10000);
                dispatch(eventLogsFailAction(msg));
            })
    };
};
	