export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const LOGGED_IN_USER_INFO_START = 'LOGGED_IN_USER_INFO_START';
export const LOGGED_IN_USER_INFO_SUCCESS = 'LOGGED_IN_USER_INFO_SUCCESS';
export const LOGGED_IN_USER_INFO_FAIL = 'LOGGED_IN_USER_INFO_FAIL';
export const LOGGED_IN_USER_INFO_RESET = 'LOGGED_IN_USER_INFO_RESET';
export const LOGGED_IN_USER_INFO_CONFIGS_SUCCESS = 'LOGGED_IN_USER_INFO_CONFIGS_SUCCESS';
export const LOGGED_IN_USER_INFO_CONFIGS_START = 'LOGGED_IN_USER_INFO_CONFIGS_START';
export const LOGGED_IN_USER_INFO_CONFIGS_FAIL = 'LOGGED_IN_USER_INFO_CONFIGS_FAIL';


export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const DATA_LOAD_START = 'DATA_LOAD_START';
export const DATA_LIST_LOAD_SUCCESS = 'DATA_LIST_LOAD_SUCCESS';
export const DATA_MAPPING_LOAD_SUCCESS = 'DATA_MAPPING_LOAD_SUCCESS';
export const DATA_LOADING_START = 'DATA_LOADING_START';
export const DATA_LOAD_FAIL = 'DATA_LOAD_FAIL';
export const STOP_LOADING = 'STOP_LOADING';
export const CLEAR_CONTEXT_TREE_DATA = 'CLEAR_CONTEXT_TREE_DATA';

export const WIDGET_DATA_LOAD_START = 'WIDGET_DATA_LOAD_START';
export const WIDGET_DATA_LIST_LOAD_SUCCESS = 'WIDGET_DATA_LIST_LOAD_SUCCESS';
export const WIDGET_DATA_LOADING_START = 'WIDGET_DATA_LOADING_START';
export const WIDGET_DATA_LOAD_FAIL = 'WIDGET_DATA_LOAD_FAIL';
export const WIDGET_STOP_LOADING = 'WIDGET_STOP_LOADING';

export const WIDGET_EVENT_LOGS_LOAD_START = 'WIDGET_EVENT_LOGS_LOAD_START'
export const WIDGET_EVENT_LOGS_LOAD_SUCCESS = 'WIDGET_EVENT_LOGS_LOAD_SUCCESS'
export const WIDGET_EVENT_LOGS_LOADING_START = 'WIDGET_EVENT_LOGS_LOADING_START'
export const WIDGET_EVENT_LOGS_LOAD_FAIL = 'WIDGET_EVENT_LOGS_LOAD_FAIL'
export const WIDGET_EVENT_LOGS_STOP_LOADING = 'WIDGET_EVENT_LOGS_STOP_LOADING'
