import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    error: null,
    loading: false,
    loading_msg: null,
    successMsg: null,
    contextData: [],
    contextMapping: []
};

const requestContext = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
        loading_msg: action.msg,
        successMsg: null,
        contextData: [],
        contextMapping: []

    });
};
const successContext = (state, action) => {
    if (action.contextData) {
        return updateObject(state, {
            error: false,
            loading: false,
            loading_msg: null,
            successMsg: action.successMsg,
            contextData: action.contextData,
            // contextMapping: action.contextData.context_mapping
        });
    }
    if (action.contextMapping) {
        return updateObject(state, {
            contextMapping: action.contextMapping
        });
    }
};
const failContext = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        loading_msg: null,
        successMsg: null,
        contextData: [],
        contextMapping: []
    });
};

const loadingStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        loading_msg: action.msg
    });
};

const stopLoading = (state, action) => {
    return updateObject(state, {
        loading: false,
        loading_msg: null

    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DATA_LOAD_START:
            return requestContext(state, action);
        case actionTypes.DATA_LIST_LOAD_SUCCESS:
            return successContext(state, action);
        case actionTypes.DATA_MAPPING_LOAD_SUCCESS:
            return successContext(state, action);
        case actionTypes.DATA_LOAD_FAIL:
            return failContext(state, action);
        case actionTypes.DATA_LOADING_START:
            return loadingStart(state, action);
        case actionTypes.STOP_LOADING:
            return stopLoading(state, action);
        default:
            return state;
    }
};

export default reducer;
