import * as actions from './actions/index';
import axios from 'axios';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const errorHandler = (dispatch, err, _5xx = false) => {
    let msg = 'Something went wrong, please try again later.';
    if (err.response) {
        if (err.response.status === 401 || (_5xx && (err.response.status >= 500 || err.message === "Network Error"))) {
            dispatch(actions.authLogout());
            dispatch(actions.loggedInUserReset());
            msg = 'Authentication information is missing or invalid';
        } else {
            msg = err.response.data && err.response.data.message;
        }
    } else if (_5xx && err.message === "Network Error") {
        dispatch(actions.authLogout());
        dispatch(actions.loggedInUserReset());
        msg = 'Authentication information is missing or invalid';

    }
    return msg;
}

export const axiosRequest = (dispatch, method, url, data) => {
    const methodLower = method.toLowerCase();


    if (['get', 'delete', 'options'].indexOf(methodLower) >= 0) {
        axios[methodLower](url, {withCredentials: true})
            .then(response => {
                return {
                    'responseStatus': 'success',
                    'responseDate': response.data
                }
            })
            .catch(err => {
                return errorHandler(err)
            })
    } else if (['put', 'post'].indexOf(methodLower) >= 0) {
        axios[methodLower](url, data, {withCredentials: true})
            .then(response => {
                return {
                    'responseStatus': 'success',
                    'responseDate': response.data
                }
            })
            .catch(err => {
                return errorHandler(err)
            })
    }

}

