import React, {useState, useEffect} from "react";
import Aux from "../../../../../hoc/Aux/Aux";
import {Dropdown} from "react-bootstrap";
import {MoreVertRounded} from "@material-ui/icons";
import classes from './ContextActions.module.css';
import {Delete, EditOutlined, AddCircleOutlineOutlined} from '@material-ui/icons';

const ContextActions = props => {
    const [currentData, setCurrentData] = useState(null);
    const [parentSize, setParentSize] = useState(0);

    useEffect(() => {
        setCurrentData(props.currentData);
        setParentSize(props.currentData[0].parent_path.length);
    }, [props.currentData]);

    const handleDeleteDialog = (newValue) => {
        props.handleBC(newValue);
        props.handleDeletePanel()
    };

    const handleParentMethods = (newValue, node_type) => {
        props.handleBC(newValue);
        props.handleSidePanel(node_type);
    };

    // console.log("CONTEXT ACTION BUTTONS");
    return (
        <Aux>
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className={classes.customDropdown}>
                    <MoreVertRounded/>
                </Dropdown.Toggle>
                <Dropdown.Menu className={classes.customDropdownMenu}>
                    <Dropdown.Item onClick={() => handleParentMethods(currentData, 'UPDATE_NODE')}
                                   disabled={parentSize === 0}
                    >
                        <EditOutlined style={{fontSize: "18px", marginRight: "3px"}}/> Edit
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleParentMethods(currentData, 'ADD_CHILD_NODE')}
                                   disabled={parentSize >= (props.mappingLength - 1)}>
                        <AddCircleOutlineOutlined style={{fontSize: "18px", marginRight: "3px"}}/>
                        Add Child
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDeleteDialog(currentData)}
                                   disabled={parentSize === 0}
                    >
                        <Delete style={{fontSize: "18px", marginRight: "3px"}}/> Delete
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Aux>
    )
}

export default ContextActions;