import React from 'react';
import Aux from "../../../../../hoc/Aux/Aux";
import {Dropdown} from "react-bootstrap";

const Dashboard = props => {

  const eventLogs = props.eventLogs;
  let selectedEventLog = 'Select Event Log';



  return (
      <Aux>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" style={{backgroundColor: "#27accd", borderColor: "#27accd"}}>
            {selectedEventLog}
          </Dropdown.Toggle>
          <Dropdown.Menu >
            {eventLogs.length > 0 ? ( eventLogs.map(event => (
                <Dropdown.Item key={event.id} style={{paddingTop: "10px"}} >
                  {event.value}
                </Dropdown.Item>
            ))) : (
              <Dropdown.Item style={{paddingTop: "10px"}} >
                  No Logs Found
                </Dropdown.Item>
            ) }
          </Dropdown.Menu>
        </Dropdown>
      </Aux>
  )
}

export default Dashboard;