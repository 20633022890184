import React, {useEffect, useState} from "react";
import Wrapper from "../../../components/UI/Wrapper/Wrapper";
import ContainerBox from "../../../components/UI/Container/ContainerBox/ContainerBox";
import {Grid} from "@material-ui/core";
import {AngularWebURL} from '../../../config';


const Dashboard = props => {

    const [count, setCount] = useState(10);
    useEffect(() => {
        document.title = "Dashboard | Yotascale";
        console.log(count);
        if (count > 0) {
            setTimeout(() => {
                setCount(count - 1);
            }, 1000)
        } else {
            window.location.href = AngularWebURL;
        }
    }, [count]);


    return (
        <Wrapper>
            <ContainerBox>
                <Grid container justify="center">
                    <h6> This domain is only for Yotascale super-admins, please click on the "Back to Yotascale" for the main application or it will be automatically redirected in {count} seconds...
                    </h6>
                </Grid>
            </ContainerBox>
        </Wrapper>
    );
};

export default Dashboard;