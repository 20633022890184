import {withStyles} from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import * as colors from '@material-ui/core/colors';


export const ToggleButtonPrimary = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(colors.blue[100]),
        backgroundColor: "#ffffff",
        padding: "5px 12px",
        '&:hover': {
            backgroundColor: "#00accd",
            color: "#ffffff"
        },
        '&.Mui-selected': {
            color: "#ffffff",
            backgroundColor: "#00accd",
            '&:hover': {
                backgroundColor: "#10bcdd",
                color: "#ffffff"
            }

        },

    },

}))(ToggleButton);


export const ToggleButtonWarning = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(colors.blue[100]),
        backgroundColor: "#ffffff",
        padding: "5px 12px",
        '&:hover': {
            backgroundColor: "#f5a623",
            color: "#ffffff"
        },
        '&.Mui-selected': {
            color: "#ffffff",
            backgroundColor: "#f5a623",
            '&:hover': {
                backgroundColor: "#f5b633",
                color: "#ffffff"
            }

        },

    },

}))(ToggleButton);


export const ToggleButtonDanger = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(colors.blue[100]),
        backgroundColor: "#ffffff",
        padding: "5px 12px",
        '&:hover': {
            backgroundColor: "#ac1b00",
            color: "#ffffff"
        },
        '&.Mui-selected': {
            color: "#ffffff",
            backgroundColor: "#ac1b00",
            '&:hover': {
                backgroundColor: "#bc2b09",
                color: "#ffffff"
            }

        },

    },

}))(ToggleButton);


