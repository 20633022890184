import React, {Suspense, useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import './Application.css';
import {Route, Switch, Redirect} from 'react-router-dom';
import Layout from '../../components/Application/Layout/Layout'
import 'react-notifications/lib/notifications.css';
import Loading from "../../components/UI/Loading/Loading";

import Logout from "../Auth/Logout/Logout";
import Dashboard from "./Dashboard/Dashboard";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import * as actions from '../../store/actions/index';
import {permissions} from '../../store/permissions'
import AccountSettings from "./AccountSettings/AccountSettings";
import {isSupportUser} from '../../Utility/common';


const Application = props => {
    const userInfo = useSelector(state => {
        return (state.user.detail && state.user.detail.user) || null;
    });


    const showBC = useSelector(state => {
        if (state.user.detail?.configs?.user?.module_permissions) {
            for (let i = 0; i < state.user.detail.configs.user.module_permissions.length; i++) {
                if (state.user.detail.configs.user.module_permissions[i].second_level_key === 'business_context'){
                    return state.user.detail.configs.user.module_permissions[i].config;
                }
            }
        }
        return false;
    });


    let routes = (
        <Switch>
            <Route path="/logout" render={(props) => <Logout {...props}  />}/>
            <Route path="/dashboard" component={Dashboard}/>
            <Redirect to="/dashboard"/>
            {/*<Redirect to="/"/>*/}
        </Switch>
    );

    if (userInfo.role
        && (isSupportUser(userInfo.username) || showBC)
        && permissions[userInfo.role]
        && permissions[userInfo.role].settings.account_configuration.bc_internal_tool) {
        routes = (
            <Switch>
                <Route path="/logout" render={(props) => <Logout {...props}  />}/>
                <Route path="/dashboard" component={Dashboard}/>
                <Route path="/account_settings/:tab" exact render={(props) => <AccountSettings {...props} />}/>
                <Redirect to="/account_settings/context"/>
                {/*<Redirect to="/"/>*/}

            </Switch>
        );
    }

    const dispatch = useDispatch();
    const getUserInfoPolling = useCallback(() => dispatch(actions.getUserInfoPolling()), [dispatch]);
    useEffect(() => {
        const getUserInfo = function () {
            getUserInfoPolling();
            const timer = setTimeout(() => {
                getUserInfo();
            }, 20000);
            return () => clearTimeout(timer);
        }
        getUserInfo();
        return function cleanup() {
            let id = window.setTimeout(() => {
            }, 0);
            while (id >= 0) {
                window.clearTimeout(id);
                id--;
            }
        };

    }, [getUserInfoPolling]);
    // console.log(['Container Application']);
    return (
        <div className="ApplicationBG">
            <div className="notification-container">
                <NotificationContainer/>
            </div>
            <Layout>
                <Suspense fallback={<Loading msg="Loading..."/>}>{routes}</Suspense>
            </Layout>
        </div>
    );
};

export default Application;
