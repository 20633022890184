export const permissions = {
    'ege9f158551e4d3481778819f2e251f0': {
        name: 'Admin',
        settings: {
            overall: true,
            api: true,
            user: {
                overall: true,
                edit_user: true,
                create_user: true,
                delete_user: true
            },
            auth: {
                overall: true,
                edit_auth: true,
                create_auth: true,
                delete_auth: true
            },
            account: {
                overall: true,
                edit_account: true,
                delete_account: true,
                create_account: true,
                check_permission: true
            },
            user_configuration: {
                overall: true,
                change_password: true,
                api_credentials: true
            },
            account_configuration: {
                overall: true,
                integration: true,
                slack_web_hook: true,
                anomaly_detection: true,
                bc_internal_tool: true,
                perspective_config: true,
                dimension_configuration: true,
                recommendations: {
                    blacklist: true,
                    purchase_preference: true
                },
                tag_mapping: true,
                    attribute_mapping: {
                    overall: true,
                    mapped_to: true,
                    show_aws_tags: true,
                    show_historical_tags: true,
                    save_policy: true,
                    search_result: true,
                    set_regex_tag_policy: true
                }
            }
        },
        tagging: {
            overall: true,
            search_tab: {
                overall: true,
                create_search: true,
                delete_search: true,
                edit_search: true,
                search_result: true,
                resource_view: true,
                bulk_update: true
            },
            auto_tag_policy_tab: {
                overall: true,
                create_policy: true,
                edit_policy: true,
                delete_policy: true
            }
        },
        analytics: {
            overall: true,
            create_perspective: true,
            delete_perspective: true,
            edit_perspective: true,
            set_budget: true,
            update_budget: true,
            export_cost_line_items: true,
            integration: {
                overall: true,
                edit_slack: true,
                edit_email: true
            },
            detailed_view: {
                overall: true,
                filter: true
            }
        },
        anomaly_detection: {
            overall: true,
            share_url_link: true,
            filter: true,
            detailed_anomaly: {
                overall: true,
                details: true,
                comments: true,
                action: {
                    overall: true,
                    resolve: true,
                    dismiss: true,
                    assign: true
                }
            }
        },
        recommendations: {
            overall: true,
            per_dimension: true,
            edit_criteria: true,
            workflow: {
                comments: true,
                snooze: true
            },
            execute_recommendation: true
        }
    },
    'fhf0f158551e4d3481778819f2e262g1': {
        name: 'Power User',
        settings: {
            overall: true,
            api: true,
            user: {
                overall: false,
                edit_user: false,
                create_user: false,
                delete_user: false
            },
            account: {
                overall: false,
                edit_account: false,
                delete_account: false,
                create_account: false,
                check_permission: false
            },
            user_configuration: {
                overall: true,
                change_password: true,
                api_credentials: true
            },
            account_configuration: {
                overall: false,
                integration: false,
                slack_web_hook: false,
                anomaly_detection: false,
                perspective_config: false,
                dimension_configuration: false,
                recommendations: {
                    blacklist: false,
                    purchase_preference: true
                },
                tag_mapping: false,
                attribute_mapping: {
                    overall: true,
                    mapped_to: true,
                    show_aws_tags: true,
                    show_historical_tags: true,
                    save_policy: true,
                    search_result: true,
                    set_regex_tag_policy: true
                }
            }
        },
        tagging: {
            overall: true,
            search_tab: {
                overall: true,
                create_search: true,
                delete_search: true,
                edit_search: true,
                search_result: true,
                resource_view: true,
                bulk_update: true
            },
            auto_tag_policy_tab: {
                overall: true,
                create_policy: true,
                edit_policy: true,
                delete_policy: true
            }
        },
        analytics: {
            overall: true,
            create_perspective: true,
            delete_perspective: true,
            edit_perspective: true,
            set_budget: true,
            update_budget: true,
            export_cost_line_items: true,
            integration: {
                overall: true,
                edit_slack: true,
                edit_email: true
            },
            detailed_view: {
                overall: true,
                filter: true
            }
        },
        anomaly_detection: {
            overall: true,
            share_url_link: true,
            filter: true,
            detailed_anomaly: {
                overall: true,
                details: true,
                comments: true,
                action: {
                    overall: true,
                    resolve: true,
                    dismiss: true,
                    assign: true
                }
            }
        },
        recommendations: {
            overall: true,
            per_dimension: true,
            edit_criteria: false,
            workflow: {
                comments: true,
                snooze: true
            },
            execute_recommendation: true
        }
    },
    '72fef158551e4d3481778819f2e2cd19': {
        name: 'Tag Admin',
        settings: {
            overall: true,
            api: true,
            user: {
                overall: false,
                edit_user: false,
                create_user: false,
                delete_user: false
            },
            account: {
                overall: false,
                edit_account: false,
                delete_account: false,
                create_account: false,
                check_permission: false
            },
            user_configuration: {
                overall: true,
                change_password: true,
                api_credentials: true
            },
            account_configuration: {
                overall: false,
                integration: false,
                slack_web_hook: false,
                anomaly_detection: false,
                perspective_config: false,
                dimension_configuration: false,
                recommendations: {
                    blacklist: false
                },
                tag_mapping: false,
                attribute_mapping: {
                    overall: true,
                    mapped_to: true,
                    show_aws_tags: true,
                    show_historical_tags: true,
                    save_policy: true,
                    search_result: true,
                    set_regex_tag_policy: true
                }
            }
        },
        tagging: {
            overall: true,
            search_tab: {
                overall: true,
                create_search: true,
                delete_search: true,
                edit_search: true,
                search_result: true,
                resource_view: true,
                bulk_update: true
            },
            auto_tag_policy_tab: {
                overall: true,
                create_policy: true,
                edit_policy: true,
                delete_policy: true
            }
        },
        analytics: {
            overall: true,
            create_perspective: false,
            delete_perspective: false,
            edit_perspective: false,
            export_cost_line_items: true,
            integration: {
                overall: false,
                edit_slack: true,
                edit_email: true
            },
            detailed_view: {
                overall: true,
                filter: true
            }
        },
        anomaly_detection: {
            overall: true,
            share_url_link: true,
            filter: true,
            detailed_anomaly: {
                overall: true,
                details: true,
                comments: true,
                action: {
                    overall: true,
                    resolve: true,
                    dismiss: true,
                    assign: true
                }
            }
        },
        recommendations: {
            overall: true,
            per_dimension: true,
            edit_criteria: false,
            workflow: {
                comments: false,
                snooze: false
            },
            execute_recommendation: true
        }
    },
    '9b94f158551e4d3481778819f2e24e22': {
        name: 'Read Only User',
        settings: {
            overall: true,
            api: true,
            user: {
                overall: false,
                edit_user: false,
                create_user: false,
                delete_user: false
            },
            account: {
                overall: false,
                edit_account: false,
                delete_account: false,
                create_account: false,
                check_permission: false
            },
            user_configuration: {
                overall: true,
                change_password: true,
                api_credentials: true
            },
            account_configuration: {
                overall: false,
                integration: false,
                slack_web_hook: false,
                anomaly_detection: false,
                perspective_config: false,
                dimension_configuration: false,
                recommendations: {
                    blacklist: false,
                    purchase_preference: true
                },
                tag_mapping: false,
                attribute_mapping: {
                    overall: false,
                    mapped_to: false,
                    show_aws_tags: false,
                    show_historical_tags: false,
                    save_policy: false,
                    search_result: false,
                    set_regex_tag_policy: false
                }
            }
        },
        tagging: {
            overall: true,
            search_tab: {
                overall: true,
                create_search: true,
                delete_search: true,
                edit_search: true,
                search_result: true,
                resource_view: true,
                bulk_update: false
            },
            auto_tag_policy_tab: {
                overall: false,
                create_policy: false,
                edit_policy: false,
                delete_policy: false
            }
        },
        analytics: {
            overall: true,
            create_perspective: false,
            delete_perspective: false,
            edit_perspective: false,
            export_cost_line_items: true,
            integration: {
                overall: false,
                edit_slack: true,
                edit_email: true
            },
            detailed_view: {
                overall: true,
                filter: true
            }
        },
        anomaly_detection: {
            overall: true,
            share_url_link: true,
            filter: true,
            detailed_anomaly: {
                overall: true,
                details: true,
                comments: true,
                action: {
                    overall: true,
                    resolve: true,
                    dismiss: true,
                    assign: true
                }
            }
        },
        recommendations: {
            overall: true,
            per_dimension: true,
            edit_criteria: false,
            workflow: {
                comments: false,
                snooze: false
            },
            execute_recommendation: true
        }
    }
};