import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as colors from '@material-ui/core/colors';


export const ButtonLightGrey = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(colors.grey[100]),
        backgroundColor: colors.grey[100],
        '&:hover': {
            backgroundColor: colors.grey[200],
        },
        '&:focus': {
            backgroundColor: colors.grey[200],
        },
    },
}))(Button);


export const ButtonWhite = withStyles((theme) => ({
    root: {
        color: "#333333",
        backgroundColor: "#ffffff",
        '&:hover': {
            backgroundColor: "#fafafa",
        },
        '&:focus': {
            backgroundColor: "#fafafa",
        },
    },
}))(Button);


export const ButtonPrimary = withStyles((theme) => ({
    root: {
        color: "#00accd",
        backgroundColor: "#ffffff",
        '&:hover': {
            backgroundColor: "#00accd",
            color: "#ffffff"
        },
        '&:focus': {
            backgroundColor: "#00accd",
            color: "#ffffff"
        },
        '&.MuiButton-outlined': {
            border: "1px solid #00accd"
        }
    },
}))(Button);


export const ButtonDanger = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(colors.red[600]),
        backgroundColor: colors.red[600],
        '&:hover': {
            backgroundColor: colors.red[800],
            color: theme.palette.getContrastText(colors.red[600]),
        },
        '&:focus': {
            backgroundColor: colors.red[800],
            color: theme.palette.getContrastText(colors.red[600]),
        },
        '&.MuiButton-outlined': {
            border: "1px solid " + colors.red[800],
            color: colors.red[600],
            backgroundColor: theme.palette.getContrastText(colors.red[600]),
            '&:hover': {
                backgroundColor: colors.red[800],
                color: theme.palette.getContrastText(colors.red[600]),
            },
            '&:focus': {
                backgroundColor: colors.red[800],
                color: theme.palette.getContrastText(colors.red[600]),
            }
        }
    },
}))(Button);


export const ButtonWarning = withStyles((theme) => ({
    root: {
        color: "#ffffff",
        backgroundColor: colors.orange[400],
        '&:hover': {
            backgroundColor: colors.orange[600],
            color: "#ffffff",
        },
        '&:focus': {
            backgroundColor: colors.orange[600],
            color: "#ffffff",
        },
        '&.MuiButton-outlined': {
            border: "1px solid " + colors.orange[600],
            color: colors.orange[400],
            backgroundColor: "#ffffff",
            '&:hover': {
                backgroundColor: colors.orange[600],
                color: "#ffffff",
            },
            '&:focus': {
                backgroundColor: colors.orange[600],
                color: "#ffffff",
            }
        }
    },
}))(Button);
