import React from "react";
import {TableBody, TableCell, TableRow} from "@material-ui/core";
import {configMapping} from "./ConfigMapping";
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
    ToggleButtonDanger,
    ToggleButtonPrimary,
    ToggleButtonWarning
} from "../../../../components/UI/MaterialUI/ToggleButton";

const CustomTableBody = props => {
    return (
        <TableBody>{props.permissions.map((item, index) => {
            if (configMapping[item.second_level_key]?.enable_to_support_team) {
                return <TableRow key={index}>
                    <TableCell>
                        {item.second_level_key}
                    </TableCell>
                    <TableCell>

                        {configMapping[item.second_level_key].title}
                        <br/>
                        <small>{configMapping[item.second_level_key].help_text}</small>
                    </TableCell>
                    <TableCell>
                        {(item.second_level_key !== 'overall_classic_app' && item.second_level_key !== 'arm') ?
                            <ToggleButtonGroup
                                value={item.config ? "True" : "False"}
                                size="small"
                                exclusive
                                name={item.second_level_key}
                                key={item.second_level_key}
                                onChange={(event, val) => {
                                    props.setSelected(item, val)
                                }}>
                                <ToggleButtonPrimary value="True" aria-label="True">True</ToggleButtonPrimary>
                                <ToggleButtonWarning value="False" aria-label="False">False</ToggleButtonWarning>
                            </ToggleButtonGroup> :
                            <ToggleButtonGroup
                                size="small"
                                exclusive
                                name={item.second_level_key}
                                key={item.second_level_key}
                                value={item.config ? "True" : "False"}
                                onChange={(event, val) => {
                                    props.setSelected(item, val)
                                }}>
                                <ToggleButtonPrimary value="True" aria-label="True">True</ToggleButtonPrimary>
                                <ToggleButtonDanger value="False" aria-label="False">False</ToggleButtonDanger>
                            </ToggleButtonGroup>
                        }
                    </TableCell>
                    <TableCell width="25%"/>
                </TableRow>
            } else {
                return null;
            }
        })}
        </TableBody>


    )
}

export default CustomTableBody;