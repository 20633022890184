import React, {Suspense} from 'react';
import classes from './Auth.module.css';
import {Route, Switch, Redirect} from 'react-router-dom';

import Container from "../../components/UI/Container/Container";
import UserPassword from "./UserPassword/UserPassword";
import Logo from "../../components/Logo/Logo";

const ForgotPassword = React.lazy(() => {
    return import('./ForgotPassword/ForgotPassword');
});

const Organization = React.lazy(() => {
    return import('./Organization/Organization');
});

const ResetPassword = React.lazy(() =>{
    return import('./ResetPassword/ResetPassword');
});

const Auth = props => {
    let routes = (
        <Switch>
            <Route path="/external_provider" render={(props) => <Organization {...props}  />}/>
            <Route path="/forgot_password" render={(props) => <ForgotPassword {...props}  />}/>
            <Route path="/reset_password" render={(props) => <ResetPassword {...props}  />}/>
            <Route path="/" exact component={UserPassword}/>
            <Redirect to="/" />
        </Switch>
    );
    return (
        <div className={classes.LoginBG}>
            <Container>
                <Logo/>
                <Suspense fallback={<p>Loading...</p>}>{routes}</Suspense>
            </Container>
        </div>
    );
};


export default Auth;