import React, {useEffect, useCallback} from "react";
import Application from "./containers/Application/Application";
import classes from './App.module.css';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from './store/actions/index'
import Auth from "./containers/Auth/Auth";
import Loading from "./components/UI/Loading/Loading";


const App = props => {

    const isAuthenticated = useSelector(state => {
        return state.user.detail || null;
    });
    const appLoaded = useSelector(state => {
        return state.user.app_loaded
    });

    const dispatch = useDispatch();
    const onTryAutoSignup = useCallback(() => dispatch(actions.loggedInUserInfo()), [dispatch]);

    useEffect(() => {
        onTryAutoSignup();
    }, [onTryAutoSignup]);

    let view = <Loading/>;
    if (appLoaded) {
        if (isAuthenticated) {
            view = <Application/>
        } else {
            view = <Auth/>
        }
    }
    return (
        <div className={classes.YotascaleMain}>
            {view}
        </div>
    );
};

export default App;
