import React, {useState} from "react";
import Aux from "../../../../../hoc/Aux/Aux";
import {Dropdown} from "react-bootstrap";
import './DistinctDashboard.css';

const DistinctDashboard = props => {

    const distinctDashboard = props.distinctDashboard;
    const [selectedDashboard, setSelectedDashboard] = useState('Cost All');

    const selectDashboard = (dash) => {
        props.loadWidgetFunction(dash);
        setSelectedDashboard(dash.split('_').join(' ').toUpperCase());
    };

    return (
        <Aux>
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" style={{backgroundColor: "#27accd", borderColor: "#27accd"}}>
                    {selectedDashboard}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {distinctDashboard.map(dashboad => (
                        <Dropdown.Item key={dashboad.dashboard} style={{paddingTop: "10px"}}
                                       onClick={() => selectDashboard(dashboad.dashboard)}>
                            {dashboad.dashboard.split('_').join(' ').toUpperCase()}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Aux>
    )
}

export default DistinctDashboard;