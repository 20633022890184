import React, {useState, useEffect} from "react";
import * as actions from '../../../../store/actions';
import {useDispatch, useSelector} from 'react-redux';

import Aux from "../../../../hoc/Aux/Aux";
import Loading from "../../../../components/UI/Loading/Loading";
import {Table, TableHead, TableRow, TableCell, TableBody, Grid, Paper, TableContainer} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {ButtonWarning, ButtonPrimary} from "../../../../components/UI/MaterialUI/Button";
import CustomTableBody from "./CustomTableBody";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 530,
    },
    alignRight: {
        textAlign: "right"
    },
    actionButtons: {
        margin: "20px 10px 30px 0",
    }
});
const Configs = props => {

    const [loadingView, setLoadingView] = useState(true);
    const [modulePermissions, setModulePermissions] = useState([]);
    const [geminiModulePermissions, setGeminiModulePermissions] = useState([]);
    const updateConfigs = (param) => dispatch(actions.updateConfigs(param));
    const user = useSelector(state => {
        return state.user;
    });

    const setSelected = (item, value) => {
        if (value === null) {
            return;
        }
        const permissionList = [...modulePermissions];
        for (const permission of modulePermissions) {
            if (item.second_level_key === permission.second_level_key) {
                permission.config = (value === 'True');
                break;
            }
        }
        setModulePermissions([...permissionList]);
    }

    const setSelectedGemini = (item, value) => {
        if (value === null) {
            return;
        }
        const permissionList = [...geminiModulePermissions];
        for (const permission of geminiModulePermissions) {
            if (item.second_level_key === permission.second_level_key) {
                permission.config = (value === 'True');
                break;
            }
        }
        setGeminiModulePermissions([...permissionList]);
    }

    const saveChanges = () => {
        const param = {};
        param.data = modulePermissions;
        param.key = 'module_permissions';
        param.ignore_sns = true
        console.log(param)
        updateConfigs(param);

        if (modulePermissions.find(o => o.second_level_key === 'project_preview').config === true) {
            const gemini_param = {};
            console.log(gemini_param)
            gemini_param.data = geminiModulePermissions;
            gemini_param.key = 'gemini_module_permissions';
            gemini_param.ignore_sns = true
            updateConfigs(gemini_param);
        }
    };

    const dispatch = useDispatch();


    useEffect(() => {
        document.title = "Edit Configs  | Yotascale";
        if (user) {
            setLoadingView(user.config_loading);
            const permissionList = user.detail.configs.tenant.module_permissions;
            permissionList.sort((a, b) => {
                return a.second_level_key > b.second_level_key;
            })
            setModulePermissions(JSON.parse(JSON.stringify(permissionList)));
            if (permissionList.some(o => (o.second_level_key === 'project_preview' && o.config === true))) {
                const geminiPermissionList = user.detail.configs.tenant.gemini_module_permissions || [];
                geminiPermissionList.sort((a, b) => {
                    return a.second_level_key > b.second_level_key;
                })
                setGeminiModulePermissions(JSON.parse(JSON.stringify(geminiPermissionList)));
            }

        }
    }, [user]);


    const resetValues = () => {
        setModulePermissions(JSON.parse(JSON.stringify(user.detail.configs.tenant.module_permissions)));
        setGeminiModulePermissions(JSON.parse(JSON.stringify(user.detail.configs.tenant.gemini_module_permissions)));
    };
    const classes = useStyles();

    return (
        <Aux>
            {loadingView && <Loading msg="Updating Contexts..."/>}
            {!loadingView && <Paper className={classes.root}>
                <Grid container>
                    <Grid xs={12} className={classes.alignRight}>
                        <ButtonWarning variant="outlined" color="danger" className={classes.actionButtons}
                                       onClick={() => resetValues()}>
                            Reset
                        </ButtonWarning>
                        <ButtonPrimary variant="outlined" color="success" className={classes.actionButtons}
                                       onClick={() => saveChanges()}>
                            Save Changes
                        </ButtonPrimary>
                    </Grid>
                </Grid>


                <Grid container>
                    <Grid xs={12}>
                        <TableContainer className={classes.container}>
                            <Table size="medium" id="configTable" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                            Key
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                            Name / Description
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                            Value
                                        </TableCell>
                                        <TableCell width="25%"/>
                                    </TableRow>
                                </TableHead>

                                <CustomTableBody permissions={modulePermissions} setSelected={setSelected}
                                                 key="modulePermissions"></CustomTableBody>
                                <CustomTableBody permissions={geminiModulePermissions} setSelected={setSelectedGemini}
                                                 key="geminiModulePermissions"></CustomTableBody>

                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Paper>}
        </Aux>
    );
};

export default Configs;
