import React from "react";
import classes from './ContainerBox.module.css';

const ContainerBox = props => {

    let title = null;
    if (props.title) {
        title = (
            <div className={classes.iboxTitle}>
                <h5>props.title</h5>
            </div>
        )
    }
    return (

        <div className={classes.ibox}>
            {title}
            <div className={classes.iboxContent}>
                {props.children}
            </div>
        </div>
    );
};


export default ContainerBox;