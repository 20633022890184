import axios from 'axios';
import * as actionTypes from './actionTypes';
import {NotificationManager} from 'react-notifications';
import {errorHandler} from '../utility';

export const requestContextAction = () => {
    return {
        type: actionTypes.DATA_LOAD_START
    };
};

export const requestLoading = (msg) => {
    return {
        type: actionTypes.DATA_LOADING_START,
        msg: msg
    };
};

export const failContextAction = (error) => {
    return {
        type: actionTypes.DATA_LOAD_FAIL,
        error: error
    };
};

export const successContextAction = (data) => {
    return {
        type: actionTypes.DATA_LIST_LOAD_SUCCESS,
        contextData: data
    };
};

export const successContextMappingAction = (data) => {
    return {
        type: actionTypes.DATA_MAPPING_LOAD_SUCCESS,
        contextMapping: data
    };
};

export const stopLoading = () => {
    return {
        type: actionTypes.STOP_LOADING
    };
};


export const getContexts = () => {
    // console.log('[get context function called]');
    return dispatch => {
        dispatch(requestContextAction());
        axios.get('/api/v2/business-contexts?send_rules=true&mapped_data=true&parent_path=true', {
            withCredentials: true
        })
            .then(response => {
                // console.log(response.data.result)
                dispatch(successContextAction(response.data.result))
            })
            .catch(err => {
                const msg = errorHandler(dispatch, err);
                NotificationManager.error(msg, '', 10000);
                dispatch(failContextAction(msg));
            });

        axios.get('/api/v2/business-contexts/mapping/contexts-mapping', {
            withCredentials: true
        })
            .then(response => {
                // console.log(response.data.result)
                dispatch(successContextMappingAction(response.data.result))
            })
            .catch(err => {
                const msg = errorHandler(dispatch, err);
                NotificationManager.error(msg, '', 10000);
                dispatch(failContextAction(msg));
            });

    };
};

export const uploadCSV = (csv) => {
    // console.log("------> uploadCSV", csv)
    if (csv)
        return dispatch => {
            dispatch(requestContextAction('Uploading CSV File...'));
            let formData = new FormData();
            // console.log(formData, csv);
            formData.append(
                "file",
                csv,
                csv.name
            );
            axios.post('/api/v2/business-context-bulk', formData, {
                withCredentials: true
            })
                .then(response => {
                    dispatch(getContexts())
                    NotificationManager.success('Please refresh the page after a couple of minutes.', 'CSV uploaded successfully', 12000);
                })
                .catch(err => {
                    const msg = errorHandler(dispatch, err);
                    NotificationManager.error("Something went wrong. Please try again later.(" + msg + ")", 'Error!', 10000);
                    dispatch(stopLoading());
                    // dispatch(failContextAction(msg));
                });
        };
};

export const deleteContextNode = (uuid) => {
    // console.log("deleteContextNode", uuid);
    return dispatch => {
        dispatch(requestLoading("Deleting Context..."));
        axios.delete('/api/v2/business-contexts/' + uuid, {
            withCredentials: true
        })
            .then(response => {
                dispatch(getContexts())
                NotificationManager.success('Node has been deleted successfully.', 'Deleted!', 10000);
            })
            .catch(err => {
                const msg = errorHandler(dispatch, err);
                NotificationManager.error(msg, '', 10000);
                dispatch(stopLoading());
                // dispatch(failContextAction(msg));
            });
    };
};

export const getTaskStatus = (task_id, count) => {
    if (!count) {
        count = 1;
    }
    return dispatch => {
        if (count <= 15) {
            axios.get('/api/v2/task/' + task_id + '/status', {
                withCredentials: true
            })
                .then(response => {
                    if (response.data.status === "success") {
                        const res = response.data.result;
                        if (res.task_status !== "SUCCESS") {
                            setTimeout(() => {
                                dispatch(getTaskStatus(task_id, count + 1));
                            }, (count) * 1000)
                        } else {
                            NotificationManager.success('Hierarchy updated successfully. Updating the list again', 'Success!', 10000);
                            dispatch(stopLoading());
                            dispatch(getContexts());
                        }
                    }
                })
                .catch(err => {
                    const msg = errorHandler(dispatch, err);
                    NotificationManager.error(msg, '', 10000);
                    dispatch(failContextAction(msg));
                });
        } else {
            dispatch(stopLoading());
        }
    }
}


export const editContextNode = (context) => {
    // console.log("editContextNode", context)
    return dispatch => {
        dispatch(requestLoading("Updating Context..."));
        const formData = {'business_context': {}};
        // if (context.rules) {
        formData.business_context['rules'] = context.rules
        // }
        if (context.parent_uuid) {
            formData.business_context['parent_uuid'] = context.parent_uuid
        }
        if (context.priority) {
            formData.business_context['priority'] = parseInt(context.priority)
        } else {
            formData.business_context['priority'] = null;
        }
        if (context.name) {
            formData.business_context['name'] = context.name
        }
        axios.put('/api/v2/business-contexts/' + context.uuid + "?mapped_data=true", formData, {
            withCredentials: true
        })
            .then(response => {
                dispatch(stopLoading());
                if (response.data.status === 'in-porgress' && response.data.task_id) {
                    NotificationManager.success('Context details has been updated successfully. Updating the hierarchy is still in progress.', "Success!", 10000);
                    dispatch(requestLoading("Waiting for the hierarchy to load, it will take few seconds..."));
                    dispatch(getTaskStatus(response.data.task_id));
                } else {
                    dispatch(getContexts());
                    NotificationManager.success('Context node has updated successfully.', "Success!", 10000);
                }
            })
            .catch(err => {
                const msg = errorHandler(dispatch, err);
                NotificationManager.error(msg, '', 10000);
                dispatch(stopLoading());
            });
    };
};

export const addContextNode = (context) => {
    // console.log("addContextNode", context)
    return dispatch => {
        dispatch(requestLoading("Creating Context..."));
        const formData = {'business_context': {}};
        formData.business_context['name'] = context.name
        formData.business_context['rules'] = context.rules
        formData.business_context['priority'] = parseInt(context.priority)
        axios.post('/api/v2/business-contexts?mapped_data=true&parent_uuid=' + context.parent_uuid, formData, {
            withCredentials: true
        })
            .then(response => {
                dispatch(getContexts());
                NotificationManager.success('Context node been created successfully.', "Success!", 10000);
            })
            .catch(err => {
                const msg = errorHandler(dispatch, err);
                NotificationManager.error(msg, '', 10000);
                dispatch(stopLoading());
            });
    };
};

export const editContextMapping = (mapping) => {
    // console.log("editContextMapping", mapping)
    return dispatch => {
        dispatch(requestLoading("Creating Context..."));
        const formData = {'context_mapping': mapping};
        axios.put('/api/v2/business-contexts/mapping/contexts-mapping', formData, {
            withCredentials: true
        })
            .then(response => {
                console.log("editContextMapping", response.data.result);
                dispatch(successContextMappingAction(response.data.result))
                dispatch(stopLoading());
                NotificationManager.success('Context Mapping hasn been updated successfully.', "Success!", 10000);
            })
            .catch(err => {
                const msg = errorHandler(dispatch, err);
                NotificationManager.error(msg, '', 10000);
                dispatch(stopLoading());
            });
    };
};