import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    detail: null,
    loading: true,
    error: null,
    config_loading: null,
    app_loaded: false
};

const loggedInUserInfoStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
        app_loaded: false,
    });
};

const loggedInUserInfoReset = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        detail: null,
        app_loaded: true,
    });
};

const loggedInUserInfoSuccess = (state, action) => {
    // console.log('[loggedInUserInfoSuccess, action]', action)
    return updateObject(state, {
        error: null,
        loading: false,
        app_loaded: true,
        detail: action.detail
    });
};

const updateConfigStart = (state, action) => {
    return updateObject(state, {
        error: null,
        config_loading: true
    });
};

const updateConfigFail = (state, action) => {
    return updateObject(state, {
        error: action.msg,
        config_loading: false
    });
};


const updateConfigs = (state, action) => {
    if (state?.detail?.configs?.tenant?.module_permissions) {
        state.detail.configs.tenant.module_permissions = action.detail;
    }
    return updateObject(state, {
        error: null,
        config_loading: null
    });;
};

const loggedInUserInfoFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        detail: null,
        app_loaded: true,
        config_loading: null
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGGED_IN_USER_INFO_START:
            return loggedInUserInfoStart(state, action);
        case actionTypes.LOGGED_IN_USER_INFO_SUCCESS:
            return loggedInUserInfoSuccess(state, action);
        case actionTypes.LOGGED_IN_USER_INFO_FAIL:
            return loggedInUserInfoFail(state, action);
        case actionTypes.LOGGED_IN_USER_INFO_RESET:
            return loggedInUserInfoReset(state, action);
        case actionTypes.LOGGED_IN_USER_INFO_CONFIGS_SUCCESS:
            return updateConfigs(state, action)
        case actionTypes.LOGGED_IN_USER_INFO_CONFIGS_START:
            return updateConfigStart(state, action)
        case actionTypes.LOGGED_IN_USER_INFO_CONFIGS_FAIL:
            return updateConfigFail(state, action)

        default:
            return state;
    }
};

export default reducer;