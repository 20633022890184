const hostName = window.location.hostname;
let serverUrl = '';
let webUrl = 'https://' + hostName;
let angularWebUrl = '';
window.segmentAnalyticsKey = "8CaT5rcgJsXXFhyox9hprtlaoITb79mf";

if (hostName === "gem-app.yotascale.io") {
    serverUrl = 'https://app-api.yotascale.io';
    angularWebUrl = "https://app.yotascale.io";

} else if (hostName === "web-internal.staging.yotascale.io") {
    serverUrl = 'https://rest-api.staging.yotascale.io';
    angularWebUrl = "https://web.staging.yotascale.io";

} else if (hostName === "web-internal.dr.yotascale.io") {
    serverUrl = 'https://rest-api.dr.yotascale.io';
    angularWebUrl = "https://web.dr.yotascale.io";

} else if (hostName === "web-internal.test.yotascale.io") {
    serverUrl = 'https://rest-api.test.yotascale.io';
    angularWebUrl = "https://web.test.yotascale.io";

} else if (hostName === "web-internal.demo-prod.yotascale.io") {
    serverUrl = 'https://rest-api.demo-prod.yotascale.io';
    angularWebUrl = "https://web.demo-prod.yotascale.io";

    //DEMO-STAGING
} else if (hostName === "web-internal.demo-staging.yotascale.io") {
    serverUrl = 'https://rest-api.demo-staging.yotascale.io';
    angularWebUrl = "https://web.demo-staging.yotascale.io";


} else if (hostName === "localhost.yotascale.io" || hostName === "localhost") {
    window.segmentAnalyticsKey = "";
    webUrl = 'http://' + hostName;
    angularWebUrl = "http://localhost.yotascale.io";

    serverUrl = 'http://localhost.yotascale.io:5000';
    // serverUrl = 'https://rest-api.staging.yotascale.io';
    // serverUrl = 'https://app-api.yotascale.io';
}

export const WebURL = webUrl + "/";
export const BaseURL = serverUrl + "/";
export const AngularWebURL = angularWebUrl + "/";
export const WebURLPrefix = "/index.html";
