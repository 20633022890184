import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import Loading from "../../../components/UI/Loading/Loading";
import { Redirect, Route, Switch } from "react-router";
import Context from "./Context/Context";
import Widget from "./Widget/Widget";
import Configs from "./Configs/Configs";
import Wrapper from "../../../components/UI/Wrapper/Wrapper";
import Aux from "../../../hoc/Aux/Aux";
import TopHeader from "../../../components/Application/Navigation/TopHeader/TopHeader";
import TopSubMenu from "../../../components/Application/Navigation/TopHeader/TopSubMenu/TopSubMenu";
import * as configs from '../../../config';
import { isSupportUser } from '../../../Utility/common';


const AccountSettings = props => {
    let routes = (
        <Switch>
            <Route path="/account_settings/context" exact render={(props) => <Context {...props} />} />
            <Route path="/account_settings/widget" exact render={(props) => <Widget {...props} />} />
            <Route path="/account_settings/configs" exact render={(props) => <Configs {...props} />} />
            <Redirect to="/account_settings/context" />
        </Switch>
    );
    const slackApp = useSelector(state => {
        if (state.user.detail.configs.tenant &&
            state.user.detail.configs.tenant.module_permissions) {
            for (let i = 0; i < state.user.detail.configs.tenant.module_permissions.length; i++) {
                if (state.user.detail.configs.tenant.module_permissions[i].second_level_key === 'slack_app') {
                    return state.user.detail.configs.tenant.module_permissions[i].config;
                }
            }
        }
        return false;
    });

    const userInfo = useSelector(state => {
        return state.user.detail.user;
    });

    const showBC = useSelector(state => {
        if (state.user.detail.configs.user &&
            state.user.detail.configs.user.module_permissions) {
            for (let i = 0; i < state.user.detail.configs.user.module_permissions.length; i++) {
                if (state.user.detail.configs.user.module_permissions[i].second_level_key === 'business_context') {
                    return state.user.detail.configs.user.module_permissions[i].config;
                }
            }
        }
        return false;
    });


    const [subMenu, setSubMenu] = useState([{
        'name': 'Attribute Mapping',
        'link': configs.AngularWebURL + '#/!/account_settings/attribute_mapping/aws',
        'active': false,
        'show': true
    }, {
        'name': 'Business Contexts',
        'link': '/account_settings/context',
        'active': props.location.pathname === '/account_settings/context',
        'show': true
    }, {
        'name': 'Widget Settings',
        'link': '/account_settings/widget',
        'active': props.location.pathname === '/account_settings/widget',
        'show': true
    }, {
        'name': 'Manage Configs',
        'link': '/account_settings/configs',
        'active': props.location.pathname === '/account_settings/configs',
        'show': true
    }, {
        'name': 'Anomaly Detection',
        'link': configs.AngularWebURL + '#/!/account_settings/anomaly_detection_configuration',
        'active': false,
        'show': true
    }, {
        'name': 'Recommendations',
        'link': configs.AngularWebURL + '#/!/account_settings/recommendations/blacklist/ec2/instance_types',
        'active': false,
        'show': true
    }, {
        'name': 'Alerts',
        'link': configs.AngularWebURL + '#/!/account_settings/account_perspectives_config',
        'active': false,
        'show': true
    }, {
        'name': 'Integrations',
        'link': configs.AngularWebURL + '#/!/account_settings/account_integration',
        'active': false,
        'show': true
    }]);
    useEffect(() => {
        const menu_ = [...subMenu];

        if (slackApp) {
            for (let k = menu_.length - 1; k > 0; k--) {
                if (menu_[k].name === "Anomaly Detection") {
                    menu_[k].show = false;
                } else if (menu_[k].name === "Integrations") {
                    menu_[k].show = false;
                }
            }
            setSubMenu(menu_);
        }
    }, []);

    useEffect(() => {
        const menu_ = [...subMenu];
        if (!isSupportUser(userInfo.username)) {
            for (let k = menu_.length - 1; k > 0; k--) {
                if (menu_[k].name === "Business Contexts" && !showBC) {
                    menu_[k].show = false;
                    if (menu_[k].active) {
                        window.location.href = '/dashboard';
                    }
                } else if (menu_[k].name === "Widget Settings" || menu_[k].name === "Manage Configs") {
                    menu_[k].show = false;
                    if (menu_[k].active) {
                        window.location.href = '/dashboard';
                    }
                }
            }
            setSubMenu(menu_);
        }
    }, []);

    return (
        <Aux>
            <TopHeader HeadingTitle="Account Settings" />
            <TopSubMenu menuList={subMenu} />
            <Wrapper>
                <Suspense fallback={<Loading msg="Loading..." />}>{routes}</Suspense>
            </Wrapper>
        </Aux>
    );
};

export default AccountSettings;