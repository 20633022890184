import React from "react";
import {Grid} from "@material-ui/core";
import classes from './TopHeader.module.css';

const TopHeader = props => {
    return (
        <Grid container className={classes.TopHeader}>
                {props.HeadingTitle}
        </Grid>
    )
}

export default TopHeader;