import React, { useState, useEffect, useCallback } from "react";
import * as actions from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from "@material-ui/core";
import Aux from "../../../../hoc/Aux/Aux";
import DistinctDashboard
    from "../../../../components/Application/AccountSettings/Widgets/DistinctDashboard/DistinctDashboard";
import EventLog from "../../../../components/Application/AccountSettings/Widgets/EventLog/EventLog";
import GlobalWidgetList
    from "../../../../components/Application/AccountSettings/Widgets/GlobalWidgetList/GlobalWidgetList";
import TenantWidgets from "../../../../components/Application/AccountSettings/Widgets/TenantWidgets/TenantWidgets";
import ContainerBox from "../../../../components/UI/Container/ContainerBox/ContainerBox";
import { ButtonWhite } from '../../../../components/UI/MaterialUI/Button';
import Loading from "../../../../components/UI/Loading/Loading";
import { eventFormatting } from "../../../../Utility/common"


const Widget = props => {
    const [distinctDashboard, setDistinctDashboard] = useState([]);
    const [globalWidgetList, setglobalWidgetList] = useState([]);
    const [perspectiveWidgetList, setperspectiveWidgetList] = useState([]);
    const [loadingView, setLoadingView] = useState(null);
    const [filterCategory, setFilterCategory] = useState(null);
    const [distinctCategory, setDistinctCategory] = useState([]);
    const [eventLogs, setEventLogs] = useState([]);

    const event_logs = useSelector(state => {
        return (state.widget.eventLogs)
    })

    const distinct_dashboard = useSelector(state => {
        return (state.widget.distinctDashboard) || []
    });
    const globel_widget = useSelector(state => {
        return (state.widget.global_widget) || []
    });
    const perspective_widget = useSelector(state => {
        return (state.widget.perspectiveWidget) || []
    });
    const outerLoading = useSelector(state => {
        return state.auth.loading
    });
    const widgetState = useSelector(state => {
        return state.widget
    });
    const tagMapping = useSelector(state => {
        let mappingMap = {};
        state.user.detail.configs.tenant.tag_mapping.map((data) => (mappingMap[data.second_level_key] = data.config))
        return mappingMap;
    });

    const hideButton = () => {
        let temp = [];
        globalWidgetList.forEach(widget => {
            if (widget.category) {
                temp.push(widget.category)
            }

        });
        if (temp.length > 0) {
            return true
        } else {
            return false
        }
    }

    const toggleEditMode = (widgetSelected, persistChange) => {
        const updatedPerspectiveWidgetList = perspectiveWidgetList.map(widget => {
            if (widget.widget_identifier === widgetSelected.widget_identifier) {
                if (widget.isEditMode && persistChange) return { ...widget, isEditMode: !widget.isEditMode, widget_order: widgetSelected.widget_order }
                else if (widget.isEditMode && !persistChange) return { ...widget, isEditMode: !widget.isEditMode }
                else return { ...widget, isEditMode: true }
            }
            return widget
        })
        setperspectiveWidgetList(updatedPerspectiveWidgetList)
    }

    const onChangeSortOrder = (widgetSelected, value) => {
        const updatedPerspectiveWidgetList = perspectiveWidgetList.map(widget => {
            if (widget.widget_identifier === widgetSelected.widget_identifier) {
                if (widget.category_order || widget.category_order === 0) return { ...widget, category_order: Number(value) }
                else return { ...widget, widget_order: Number(value) }
            }
            return widget
        })
        setperspectiveWidgetList(updatedPerspectiveWidgetList)
    }


    useEffect(() => {
        if (outerLoading === false && widgetState.loading === true) {
            let msg = "Loading Widgets...";
            if (widgetState.loading_msg) {
                msg = widgetState.loading_msg;
            }
            setLoadingView(<Loading msg={msg} />);
        } else {
            setLoadingView(null);
        }

        // const eventLogs = event_logs
        const eventLogsList = (event_logs.map(event => {
            return eventFormatting(event)
        }))
        setEventLogs(eventLogsList)

        const distinctDashboard = (distinct_dashboard.map(({ ...widget_dashboard }) => widget_dashboard));
        setDistinctDashboard(distinctDashboard);

        const widgetList = (globel_widget.map(({ ...global_widget_list }) => global_widget_list));
        setglobalWidgetList(widgetList);

        const perspectiveWidgetList = (perspective_widget.map(({ ...widget_list }) => widget_list));
        setperspectiveWidgetList(perspectiveWidgetList);

        let localDistinctCategory = [];
        widgetList.forEach(widget => {
            if (!localDistinctCategory.includes(widget.category)) {
                localDistinctCategory.push(widget.category);
            }
        });
        setDistinctCategory(localDistinctCategory);
        setFilterCategory(localDistinctCategory[0])

    }, [outerLoading, distinct_dashboard, globel_widget, perspective_widget, event_logs]);

    const dispatch = useDispatch();

    const loadEventLogs = useCallback(() => dispatch(actions.getWidgetsEventLogs()), [dispatch]);

    const loadWidgets = useCallback((widget) => dispatch(actions.getWidgets(widget)), [dispatch]);
    useEffect(() => {
        loadWidgets('cost_all');
        loadEventLogs();
    }, [loadWidgets, loadEventLogs]);

    return (
        <Aux>
            {loadingView}
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={2} style={{ padding: "10px 0" }}>
                            <DistinctDashboard distinct_dashboard={distinct_dashboard.widgetData}
                                distinctDashboard={distinctDashboard} loadWidgetFunction={loadWidgets}
                            />
                        </Grid>
                        <Grid item xs={8} style={{ padding: "10px 0" }}>
                            <div>
                                {distinctCategory.map((category) => (
                                    hideButton() &&
                                    <ButtonWhite variant="outlined" onClick={() => setFilterCategory(category)}
                                        key={category}
                                        style={{
                                            marginRight: "5px", marginLeft: "5px",
                                            borderColor: (category === filterCategory) ? '#007bff' : 'rgba(0, 0, 0, 0.23)'
                                        }}>
                                        {category}
                                    </ButtonWhite>
                                ))}

                            </div>
                        </Grid>
                        <Grid item xs={2} style={{ padding: "10px 0" }}>
                            <EventLog title="Event Log" eventLogs={eventLogs} />
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ContainerBox justify="flex-end">
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6} style={{ padding: "5px", border: "3px solid #F0F0F0" }}>
                                    <GlobalWidgetList globel_widget={globel_widget.widgetData}
                                        globalWidgetList={globalWidgetList} tagMapping={tagMapping}
                                        filterCategory={filterCategory}
                                        perspectiveWidgetID={perspectiveWidgetList.map((data) => (data.widget_identifier))}
                                    />
                                </Grid>
                                <Grid item xs={6} style={{ padding: "5px", border: "3px solid #F0F0F0" }}>
                                    <TenantWidgets perspective_widget={perspective_widget.widgetData}
                                        perspectiveWidgetList={perspectiveWidgetList}
                                        tagMapping={tagMapping} filterCategory={filterCategory}
                                        toggleEditMode={toggleEditMode}
                                        onChangeSortOrder={onChangeSortOrder} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </ContainerBox>
                </Grid>
            </Grid>
        </Aux>
    );
};

export default Widget;
