import React, {useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Toolbar,
    Typography,
    Checkbox,
    Button,
    Tooltip
} from "@material-ui/core";
import {AddBox} from '@material-ui/icons'
import {useDispatch} from "react-redux";
import * as actions from "../../../../../store/actions";
import Modal from "react-bootstrap/Modal";

const GlobalWidgetList = props => {
    const filterCategory = props.filterCategory;

    document.title = "Widget List | Yotascale";
    const perspectiveWidgetID = props.perspectiveWidgetID;
    const globalWidgetList = props.globalWidgetList;
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 600,
        }
    });
    const classes = useStyles();
    const page = 0;
    const rowsPerPage = 50;
    const [numSelected, setNumSelected] = useState(0);
    const [addWidgets, setAddWidgets] = useState([]);
    const dispatch = useDispatch();
    const addWidgetMethod = (id) => dispatch(actions.addwidget(id));
    const addWidget = () => {
        console.log("add Widgets functions", addWidgets);
        addWidgetMethod(addWidgets);
    };
    const hideCategory = () => {
        let temp = [];
        globalWidgetList.forEach(widget => {
            if (widget.category) {
                temp.push(widget.category)
            }
        });
        if (temp.length > 0) {
            return true
        } else {
            return false
        }
    }
    const sortOrderNumber = () => {
        globalWidgetList.sort((a, b) => (a.widget_order > b.widget_order) ? 1 : ((b.widget_order > a.widget_order) ? -1 : 0));
    }
    sortOrderNumber();
    const onCheckChange = (event, widget) => {
        const target = event.target;
        var num = numSelected;
        if (target.checked) {
            setNumSelected(num + 1);
        } else {
            setNumSelected(num - 1);
        }
        let obj = addWidgets.find(o => o.widget_identifier === widget.widget_identifier);
        if (!obj) {
            addWidgets.push(widget);
        } else {
            let temp = [];
            addWidgets.forEach((wid) => {
                if (wid.widget_identifier !== widget.widget_identifier) {
                    temp.push(wid);
                }
            });
            addWidgets.length = 0;
            temp.forEach((widget) => {
                addWidgets.push(widget)
            });
        }
        console.log("add Widgets", addWidgets);
    };
    const [show, setShow] = useState(false);

    const handleClose = (check) => {
        if (check) {
            addWidget();
            setNumSelected(0);
            setAddWidgets([]);
        }
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
    }

    return (
        <TableContainer className={classes.container}>
            <Toolbar style={{
                top: 0,
                left: 0,
                zIndex: 3,
                position: 'sticky',
                backgroundColor: '#fafafa',
                borderBottom: '1px solid #e0e0e0'
            }}>
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Global Widgets
                </Typography>
                {numSelected > 0 ? (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div"
                                style={{paddingLeft: '58%'}}>
                        <Tooltip title="ADD">
                            <Button aria-label="ADD" color="primary" variant="outlined"
                                    style={{color: '#27accd', borderColor: '#27accd'}}
                                    onClick={() => handleShow()}>
                                <AddBox/>
                                <span style={{fontSsize: '20px', paddingLeft: '10px'}}>{numSelected} widget</span>
                            </Button>
                        </Tooltip>
                        <div>
                            <Modal show={show} onHide={handleClose} style={{top: '200px'}}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirm Add</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Are you sure want to add selected widgets ?</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => handleClose(0)}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" onClick={() => handleClose(1)}>
                                        Add
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>

                    </Typography>
                ) : null}
            </Toolbar>

            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="left" style={{fontWeight: 'bold', fontSize: 12}}>Widget Name</TableCell>
                        <TableCell align="left" style={{fontWeight: 'bold', fontSize: 12}}>Type</TableCell>
                        {/* {hideCategory() && <TableCell align="left" style={{fontWeight: 'bold', fontSize: 12}}>Category(Sort
                            Order)</TableCell>} */}
                        <TableCell align="center" style={{fontWeight: 'bold', fontSize: 12}}>Sort Order</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {globalWidgetList.filter(filtercategory => (filtercategory.category === filterCategory && perspectiveWidgetID.indexOf(filtercategory.widget_identifier) < 0)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((widget) => (
                        <TableRow hover role="checkbox" key={widget.widget_identifier}>
                            <TableCell align="left">
                                <Checkbox onChange={e => onCheckChange(e, widget)}/>
                                {/*disabled={widget.widget_ws_name === 'tag_generic' && !(widget.widget_filter_label.toLowerCase() in props.tagMapping)}*/}
                            </TableCell>
                            <TableCell align="left"
                                       style={{
                                           fontSize: 12,
                                           color: (widget.widget_filter_label.toLowerCase() in props.tagMapping) ? '#000000' : 'inherit'
                                           // (widget.widget_ws_name === 'tag_generic' ? '#b3b3b3' : 'inherit')
                                       }}>
                                {widget.widget_filter_label.toLowerCase() in props.tagMapping ? props.tagMapping[widget.widget_filter_label.toLowerCase()] : widget.widget_filter_label}
                            </TableCell>
                            <TableCell align="left"
                                       style={{
                                           fontSize: 12,
                                           color: (widget.widget_filter_label.toLowerCase() in props.tagMapping) ? '#000000' : 'inherit'
                                           // (widget.widget_ws_name === 'tag_generic' ? '#b3b3b3' : 'inherit')
                                       }}>
                                {widget.widget_ws_name === 'tag_generic' ? 'Tags' : 'Generic'}
                            </TableCell>
                            {/* {hideCategory() &&
                            <TableCell align="left" style={{
                                fontSize: 12,
                                color: (widget.widget_filter_label.toLowerCase() in props.tagMapping) ? '#000000' : 'inherit'
                                // (widget.widget_ws_name === 'tag_generic' ? '#b3b3b3' : 'inherit')
                            }}>{widget.category} ({widget.category_order})</TableCell>} */}
                            <TableCell align="center"
                                       style={{
                                           fontSize: 12,
                                           color: (widget.widget_filter_label.toLowerCase() in props.tagMapping) ? '#000000' : 'inherit'
                                           // : (widget.widget_ws_name === 'tag_generic' ? '#b3b3b3' : 'inherit')
                                       }}>
                                {hideCategory() ? widget.category_order : widget.widget_order}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    );
};

export default GlobalWidgetList;