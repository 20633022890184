import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    error: null,
    loading: false,
    successMsg: null,
    token: null,
    user_uuid: null,
    tenant_uuid: null,
    resetEmail: null
};

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
        successMsg: null

    });
};

const authReset = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        successMsg: null,
        token: null,
        user_uuid: null,
        tenant_uuid: null,
        resetEmail: null
    });
};


const authSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false,
        successMsg: action.successMsg,
        token: action.token,
        user_uuid: action.user_uuid,
        tenant_uuid: action.tenant_uuid,
        resetEmail: action.resetEmail
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        successMsg: null
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_RESET:
            return authReset(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authReset(state, action)
        default:
            return state;
    }
};

export default reducer;