import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { makeStyles } from '@material-ui/core/styles';
import {
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Toolbar,
    Typography,
    Checkbox,
    Button,
    Tooltip,
    Input
} from "@material-ui/core";
import { Delete } from '@material-ui/icons'
import { useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import * as actions from "../../../../../store/actions";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import EditIcon from "@material-ui/icons/EditOutlined";
import { NotificationManager } from 'react-notifications';


const TenantWidgets = props => {
    document.title = "Widget List | Yotascale";
    const perspectiveWidgetList = props.perspectiveWidgetList;
    const toggleEditMode = props.toggleEditMode;
    const onChangeSortOrder = props.onChangeSortOrder
    const filterCategory = props.filterCategory;
    const page = 0;
    const rowsPerPage = 50;
    const [numSelected, setNumSelected] = useState(0);
    const [deleteWidgets, setDeleteWidgets] = useState([]);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const deleteWidgetMethod = (id) => dispatch(actions.deleteWidgets(id));
    const deleteWidget = () => {
        deleteWidgetMethod(deleteWidgets);
    }
    const updateWidgetMethod = (widget) => dispatch(actions.updateWidget(widget));

    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 600,
        },
    });

    const classes = useStyles();

    const hideCategory = () => {
        let temp = [];
        perspectiveWidgetList.forEach(widget => {
            if (widget.category) {
                temp.push(widget.category)
            }
        });
        if (temp.length > 0) {
            return true
        } else {
            return false
        }
    }

    const sortOrderNumber = () => {
        if (hideCategory) perspectiveWidgetList.sort((a, b) => (a.category_order > b.category_order) ? 1 : ((b.category_order > a.category_order) ? -1 : 0));
        else perspectiveWidgetList.sort((a, b) => (a.widget_order > b.widget_order) ? 1 : ((b.widget_order > a.widget_order) ? -1 : 0));
    };

    sortOrderNumber();

    const onCheckChange = (event, id) => {
        const target = event.target;
        var num = numSelected;
        if (target.checked) {
            setNumSelected(num + 1);
        } else {
            setNumSelected(num - 1);
        }
        if (!deleteWidgets.includes(id)) {
            deleteWidgets.push(id);
        }
        else {
            let temp = [];
            deleteWidgets.forEach((widget) => {
                if (widget !== id) {
                    temp.push(widget);
                }
            });
            deleteWidgets.length = 0;
            temp.forEach((widget) => {
                deleteWidgets.push(widget)
            });
        }
        console.log("Delete Widgets", deleteWidgets);
    };

    const handleClose = (check) => {
        if (check) {
            deleteWidget();
            setNumSelected(0);
            setDeleteWidgets([]);
        }
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    };

    const onToggleEditMode = widgetSelected => {
        toggleEditMode(widgetSelected, false)
    };

    const onUpdateSortOrder = widgetSelected => {
        if (widgetSelected.category_order === 0) NotificationManager.error("Cannot Set Sort Order to 0", '', 10000)
        else {
            toggleEditMode(widgetSelected, true)

            //API Put Call for Updating the values in the backend
            delete widgetSelected.isEditMode
            updateWidgetMethod(widgetSelected)
        }

    }

    const onChange = (event, widgetSelected) => {
        onChangeSortOrder(widgetSelected, event.target.value)
    }

    return (
        <TableContainer className={classes.container}>
            <Toolbar style={{ top: 0, left: 0, zIndex: 3, position: 'sticky', backgroundColor: '#fafafa', borderBottom: '1px solid #e0e0e0' }}>
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Customer Widgets
                </Typography>
                {numSelected > 0 ? (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div"
                        style={{ paddingLeft: '52%' }}>
                        <Tooltip title="Delete">
                            <Button aria-label="delete" variant="outlined" color="secondary" onClick={() => handleShow()}>
                                <Delete />
                                <span style={{ fontSsize: '20px', paddingLeft: '10px' }}>{numSelected} widget</span>
                            </Button>
                        </Tooltip>
                        <Modal show={show} onHide={handleClose} style={{ top: '200px' }}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm Remove</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure want to remove selected widgets ?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => handleClose(0)}>
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={() => handleClose(1)}>
                                    Remove
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Typography>
                ) : null}
            </Toolbar>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow style={{ backgroundColor: "#F0F0F0" }}>
                        <TableCell></TableCell>
                        <TableCell align="left" style={{ fontWeight: 'bold', fontSize: 12 }}>Widget Name</TableCell>
                        <TableCell align="left" style={{ fontWeight: 'bold', fontSize: 12 }}>Type</TableCell>
                        {/* {hideCategory() && <TableCell align="left" style={{ fontWeight: 'bold', fontSize: 12 }}>Category(Sort
                            Order)</TableCell>} */}
                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: 12 }}>Sorting Order</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: 12 }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {perspectiveWidgetList.filter(filtercategory => filtercategory.category === filterCategory).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((widget) => (
                        <TableRow hover role="checkbox" key={widget.widget_identifier}>
                            <TableCell align="left">
                                <Checkbox onChange={e => onCheckChange(e, widget.pw_identifier)} />
                            </TableCell>
                            <TableCell align="left"
                                style={{
                                    fontSize: 12,
                                }}>
                                {(widget.widget_filter_label && widget.widget_filter_label.toLowerCase() in props.tagMapping) ? props.tagMapping[widget.widget_filter_label.toLowerCase()] : widget.widget_filter_label}
                            </TableCell>
                            <TableCell align="left"
                                style={{
                                    fontSize: 12,
                                }}>
                                {widget.widget_ws_name === 'tag_generic' ? 'Tags' : 'Generic'}
                            </TableCell>
                            {/* {hideCategory() && <TableCell align="left"
                                style={{
                                    fontSize: 12,
                                }}>{widget.category} ({widget.category_order})</TableCell>} */}
                            <TableCell align="center" style={{
                                fontSize: 12,
                            }}>{widget.isEditMode ? (
                                <Input
                                    value={hideCategory() ? widget.category_order : widget.widget_order}
                                    name='sort_order'
                                    onChange={e => onChange(e, widget)}
                                    type="number"
                                // className={classes.input}
                                />
                            ) : (hideCategory() ? widget.category_order : widget.widget_order)}</TableCell>
                            <TableCell className={classes.selectTableCell}>
                                {widget.isEditMode ? (
                                    <>
                                        <IconButton
                                            aria-label="done"
                                            onClick={() => onUpdateSortOrder(widget)}
                                        >
                                            <DoneIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <IconButton
                                        aria-label="edit"
                                        onClick={() => onToggleEditMode(widget)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TenantWidgets;
