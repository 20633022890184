import React from "react";
import classes from "./Loading.module.css";
import Aux from "../../../hoc/Aux/Aux";

const Loading = props => {
    let msg = props.msg || "Loading...";
    return (
        <Aux>
            <div className={classes.OverlayContainer} style={props.styleOverload}/>
            <div className={classes.OverlayContent} style={props.contentStyleOverload}>
                <div className={classes.Text}>
                    {msg}
                </div>
                <div className={classes.ProgressBar}>
                    <div className={classes.BarSlide} />
                </div>
            </div>
        </Aux>
    );
};

export default Loading;

