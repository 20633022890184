import React from "react";
import Btn from 'react-bootstrap/Button';
import classes from './Button.module.css';

const Button = props => {
    let addedClass = [];
    switch (props.variant) {
        case 'primary':
            addedClass = [classes.Primary];
            break;
        case 'link':
            addedClass = [classes.Link];
            switch (props.color) {
                case 'white':
                    addedClass.push(classes.WhiteText)
                    break;
                case 'black':
                    addedClass.push(classes.BlackText)
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }
    return <Btn className={addedClass.join(' ')} {...props}>{props.children}</Btn>;
};

export default Button;