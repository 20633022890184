import React from "react";
import classes from './TopSubMenu.module.css';
import {Grid} from "@material-ui/core";

const TopSubMenu = props => {

    let list = [];
    let menuList = props.menuList || [];
    for (let index = 0; index < menuList.length; index++) {
        if (menuList[index].show) {
            list.push(
                <li key={index.toString()}>
                    <strong style={{cursor: "pointer"}}>
                        <a href={menuList[index].link}
                           className={menuList[index].active ? classes.customBreadCrumbLink : ''}>
                            {menuList[index].name}
                        </a>
                    </strong>
                </li>
            )
        }
    }

    return (
        <Grid container className={classes.TopSubMenu}>
            <ol className={classes.customBreadCrumb}>
                {list}
            </ol>
        </Grid>
    )
}


export default TopSubMenu;