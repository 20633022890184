import React from "react";
import classes from './Navigation.module.css';
import ArrowBackIosRounded from '@material-ui/icons/ArrowBackIosRounded';

import {Nav, Navbar} from "react-bootstrap";
import Logo from './Logo/Logo';
import UserDropdown from './UserDropdown/UserDropdown';
import * as Configs from '../../../config';


const Navigation = () => {

    return (
        <Navbar bg="dark" variant="dark" fixed="top" className={classes.Navigation}>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href={Configs.AngularWebURL} className={classes.BackLink}>
                        <ArrowBackIosRounded style={{margin: "0 5px 3px 0"}} />
                        Back to Yotascale
                    </Nav.Link>
                </Nav>
                <Nav>
                    <Navbar.Brand href="/"><Logo/></Navbar.Brand>
                    <UserDropdown />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )

};

export default Navigation;