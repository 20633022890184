import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";

import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import Aux from "../../../../../../hoc/Aux/Aux";
import * as actions from '../../../../../../store/actions';
import {ButtonWarning, ButtonDanger} from "../../../../../UI/MaterialUI/Button";


const DeleteDialog = props => {
    const [openDialog, setOpenDialog] = useState(false);
    const [currentBC, setCurrentBC] = useState("this");
    useEffect(() => {
        if (props.showUpDeleteDialogProp) {
            setOpenDialog(props.showUpDeleteDialogProp);
        }
    }, [props.showUpDeleteDialogProp])

    useEffect(() => {
        if (props.selectedBC) {
            setCurrentBC(props.selectedBC[0]);
        }
    }, [props.selectedBC])


    const dispatch = useDispatch();
    const deleteNodeMethod = (uuid) => dispatch(actions.deleteContextNode(uuid));


    const handleCloseDialog = () => {
        if (props.onHide) {
            props.onHide()
        } else {
            setOpenDialog(false);
        }
    };

    const handleDeleteDialog = (uuid) => {
        deleteNodeMethod(uuid);
        if (props.onHide) {
            props.onHide()
        } else {
            setOpenDialog(false);
        }
    };

    return (
        <Aux>
            <Dialog
                open={openDialog}
                onClose={() => handleCloseDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete {currentBC.name} ?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span>Are you sure, you want to delete </span>
                        <strong>{(currentBC.parent_path ? (currentBC.parent_path.join(" / ") + " / " + currentBC.name) : currentBC.name)}</strong>
                        <span> node ? </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonWarning onClick={() => handleCloseDialog()} variant="outlined">
                        Cancel
                    </ButtonWarning>
                    <ButtonDanger onClick={() => handleDeleteDialog(currentBC.uuid)} variant="outlined">
                        Delete
                    </ButtonDanger>
                </DialogActions>
            </Dialog>
        </Aux>
    )
}

export default DeleteDialog;