import React, {useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import {
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TablePagination,
    TableFooter
} from "@material-ui/core";

import classes from './ContextList.module.css';
import './ContextList.css';
import ContextActions from "../ContextActions/ContextActions";
import BCListTableHeader from '../../../../UI/MaterialUI/BCListTableHead'
import TablePaginationActions from '../../../../UI/MaterialUI/TablePagination'

const ContextList = props => {

    const tenant_name = useSelector(state => {
        return (state.user && state.user.detail && state.user.detail.tenant.name) || null
    });
    let headerValue = "Context:";
    if (props.context && props.contextMapping) {
        headerValue += props.contextMapping.map((nm, index) => (
            (index === 0 ? tenant_name : nm.name)
        )).join(" / ");
    }


    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('priority');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [bcList, setBCList] = useState([]);

    document.title = "Context List | Yotascale";

    useEffect(() => {
        if (props.context) {
            const x = JSON.parse(JSON.stringify(props.context))
            x.map(bc => (bc.complete_path = bc.parent_path && bc.parent_path.length > 0 ? (bc.parent_path.join(" / ") + " / " + bc['name']) : bc['name']));
            setBCList(x);
        }
        setPage(0);
    }, [props.context])

    let emptyRows = 0;
    // if (bcList.length > 0) {
    //     emptyRows = rowsPerPage - Math.min(rowsPerPage, bcList.length - page * rowsPerPage);
    // }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    function descendingComparator(a, b, orderBy) {
        let aa = a[orderBy];
        let bb = b[orderBy];
        //this check is basically move the null to the end of the max
        if (orderBy === 'priority') {
            aa = aa || Number.MAX_SAFE_INTEGER;
            bb = bb || Number.MAX_SAFE_INTEGER;
        }
        if (bb < aa) {
            return -1;
        }
        if (bb > aa) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    // console.log(['LIST VIEW']);


    return (
        <TableContainer className={classes.Container}>
            <Table stickyHeader aria-label="sticky table">
                <BCListTableHeader
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    context_name={headerValue}
                />

                <TableBody>
                    {(rowsPerPage > 0 ? (stableSort(bcList, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ) : (stableSort(bcList, getComparator(order, orderBy)))).map((ct) => (
                        <TableRow key={ct.uuid} hover>
                            <TableCell>{ct.complete_path}</TableCell>
                            <TableCell>{ct.priority}</TableCell>
                            <TableCell>{ct.rules}</TableCell>
                            <TableCell style={{textAlign: "center"}}>
                                <ContextActions currentData={[ct]} handleBC={props.handleBC}
                                                mappingLength={props.contextMapping && props.contextMapping.length}
                                                handleSidePanel={props.handleSidePanel}
                                                handleDeletePanel={props.handleDeletePanel}/>
                            </TableCell>
                        </TableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{height: 53 * emptyRows}}>
                            <TableCell colSpan={6}/>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[50, 100, 150, {label: 'All', value: -1}]}
                            colSpan={4}
                            count={bcList.length}
                            rowsPerPage={rowsPerPage}
                            page={bcList.length > 0 ? page : 0}
                            SelectProps={{
                                inputProps: {'aria-label': 'rows per page'},
                                native: true,
                            }}
                            labelDisplayedRows={(arg) => {
                                const from = arg.from;
                                const to = arg.to === -1 ? arg.count : arg.to;
                                const count = arg.count
                                return from + "-" + to + " of " + count;
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default ContextList;
