import {errorHandler} from '../utility';
import axios from 'axios';
// import * as Configs from '../../config';
import {authSuccess} from './authentication';
import {NotificationManager} from 'react-notifications';


import * as actionTypes from './actionTypes';

export const loggedInUserStart = () => {
    return {
        type: actionTypes.LOGGED_IN_USER_INFO_START
    };
};

export const loggedInUserFail = (msg) => {
    return {
        type: actionTypes.LOGGED_IN_USER_INFO_FAIL,
        error: msg
    };
};

export const loggedInUserSuccess = (result) => {
    return {
        type: actionTypes.LOGGED_IN_USER_INFO_SUCCESS,
        detail: result
    }
};


export const updateConfigSuccess = (data) => {
    return {
        type: actionTypes.LOGGED_IN_USER_INFO_CONFIGS_SUCCESS,
        detail: data
    }
};


export const loggedInUserReset = () => {
    return {
        type: actionTypes.LOGGED_IN_USER_INFO_RESET
    }
};
export const updateConfigStart = () => {
    return {
        type: actionTypes.LOGGED_IN_USER_INFO_CONFIGS_START
    }
};
export const failConfigAction = (msg) => {
    return {
        type: actionTypes.LOGGED_IN_USER_INFO_CONFIGS_FAIL,
        msg: msg
    }
};



export const loggedInUserInfo = () => {
    // console.log('[loggedInUSer function called]');
    return dispatch => {
        dispatch(loggedInUserStart());
        axios.get('user/logged_in_user', {withCredentials: true})
            .then(response => {
                const result = response.data.result;
                const userUuid = result.user.uuid;
                const tenantUuid = result.tenant.tenant_uuid;
                const userRoleUuid = result.user.role;
                const lastRefresh = result.user.refresh || 'none';
                localStorage.setItem('ys_logged_in_user_id', userUuid);
                localStorage.setItem('ys_logged_in_tenant_id', tenantUuid);
                localStorage.setItem('ys_user_refresh', lastRefresh);
                localStorage.setItem('ys_user_role_uuid', userRoleUuid);
                dispatch(authSuccess(userUuid, tenantUuid));
                dispatch(loggedInUserSuccess(response.data.result))

            })
            .catch(err => {
                const msg = errorHandler(dispatch, err, true);
                NotificationManager.error(msg);
            });

    };
};


export const getUserInfoPolling = () => {
    return dispatch => {
        axios.get('user/info', {withCredentials: true})
            .then(response => {
                const data = response.data;
                const checkUserUuid = data.uuid;
                const checkUserRoleUuid = data.role;
                const checkLastRefresh = data.refresh || 'none';
                const lastRefresh = localStorage.getItem('ys_user_refresh');
                const userRoleUuid = localStorage.getItem('ys_user_role_uuid');
                const user_uuid = localStorage.getItem('ys_logged_in_user_id');

                if (checkUserUuid !== user_uuid || checkUserRoleUuid !== userRoleUuid || checkLastRefresh !== lastRefresh) {
                    dispatch(loggedInUserInfo());
                }


            })
            .catch(err => {
                const msg = errorHandler(dispatch, err, true);
                console.log(msg);
                // NotificationManager.error(msg);
            });

    };
};


export const updateConfigs = (param) => {
    return dispatch => {
        dispatch(updateConfigStart())
        axios.post(`tenant_settings/${param.key}`, param, {withCredentials: true})
            .then(response => {
                dispatch(updateConfigSuccess(param.data))
            })
            .catch(err => {
                const msg = errorHandler(dispatch, err, true);
                dispatch(failConfigAction(msg));
                NotificationManager.error(msg);
            });

    };
};
