export const configMapping = {
    'gemini_only': {
        title: 'Gemini Feature Flag - Gemini App only',
        help_text: 'If it will be OFF then we will show user management and connection management of Classic App',
        enable_to_support_team: true
    },
    'k8_cluster': {
        title: 'List of Clusters (K8s, ECS, EKS)',
        help_text: 'Widget on Manage Connections Screen',
        enable_to_support_team: true
    },
    'slack_app': {
        title: 'New Slack Application',
        help_text: '',
        enable_to_support_team: true
    },
    'aws_savings_plans_optimization': {
        title: ' AWS Savings Plan',
        help_text: '',
        enable_to_support_team: true
    },
    'project_preview': {
        title: 'Project(Gemini) Preview Button on Classic',
        help_text: '',
        enable_to_support_team: true
    },
    'arm_budget': {
        title: 'Budget Module',
        help_text: '',
        enable_to_support_team: false
    },
    'arm': {
        title: 'Executive Dashboard',
        help_text: 'if Overall Application = True and this is False, then Application will stuck in a loop on main page.',
        enable_to_support_team: true
    },
    'second_level_context_widget': {
        title: '',
        help_text: '',
        enable_to_support_team: false
    },
    'arm_reserve_capacity_utilization': {
        title: 'EC2 RI Utilization',
        help_text: '',
        enable_to_support_team: true
    },
    'tags': {
        title: 'Tags Module',
        help_text: '',
        enable_to_support_team: false
    },
    'azure_onboarding': {
        title: 'Azure Onboarding',
        help_text: '',
        enable_to_support_team: true
    },
    'account_configuration_anomaly_detection': {
        title: ' AD menu on account settings page',
        help_text: '',
        enable_to_support_team: false
    },
    'account_configuration_integration': {
        title: 'Integration menu on account settings page',
        help_text: '',
        enable_to_support_team: false
    },
    'account_settings': {
        title: 'Account Settings Module',
        help_text: '',
        enable_to_support_team: false
    },
    'account_settings_dimension_configuration': {
        title: 'Dimension Config on account settings page',
        help_text: '',
        enable_to_support_team: false
    },
    'account_settings_recommendations': {
        title: 'Recommendations menu on account settings page',
        help_text: '',
        enable_to_support_team: false
    },
    'anomaly_detection': {
        title: ' Anomaly Detection Module',
        help_text: '',
        enable_to_support_team: true
    },
    'arm_cost_line_item': {
        title: 'Cost Line Items Module',
        help_text: '',
        enable_to_support_team: true
    },
    'arm_rds_reserve_capacity_utilization': {
        title: 'RDS RI Utilization',
        help_text: '',
        enable_to_support_team: true
    },
    'arm_elasticache_reserve_capacity_utilization': {
        title: ' Elastic Cache RI Utilization',
        help_text: '',
        enable_to_support_team: true
    },
    'arm_redshift_reserve_capacity_utilization': {
        title: 'Redshift RI Utilization',
        help_text: '',
        enable_to_support_team: true
    },
    'arm_all_performance': {
        title: 'Performance Module',
        help_text: '',
        enable_to_support_team: false
    },
    'recommendations': {
        title: 'Recommendation Module',
        help_text: '',
        enable_to_support_team: true
    },
    'tags_auto_policy_tab': {
        title: 'Auto Policy Module',
        help_text: '',
        enable_to_support_team: false
    },
    'tags_configure_tab': {
        title: ' Tags Configure Moduel',
        help_text: '',
        enable_to_support_team: false
    },
    'tags_search_tab': {
        title: 'Tags Search Module',
        help_text: '',
        enable_to_support_team: false
    },
    'user_settings': {
        title: 'User Settings Module',
        help_text: '',
        enable_to_support_team: false
    },
    'user_settings_api_credentials': {
        title: 'User settings API Credentials Module ',
        help_text: '',
        enable_to_support_team: false
    },
    'user_settings_api_link': {
        title: 'Yotascale API Link',
        help_text: '',
        enable_to_support_team: false
    },
    'recommendation_purchase': {
        title: 'Recommendation Purchase Module',
        help_text: '',
        enable_to_support_team: true
    },
    'cur_support': {
        title: ' Cur Support',
        help_text: '',
        enable_to_support_team: false
    },
    'tags_additional_custom_tags': {
        title: 'Additional Custom Tags Module',
        help_text: '',
        enable_to_support_team: false
    },
    'overall_classic_app': {
        title: 'Overall Classic Application',
        help_text: 'It will work if at least one account is added to the manage connection screen.',
        enable_to_support_team: true
    },
    'cost_analytics': {
        title: 'Gemini Feature Flag for Cost Analytics',
        help_text: '********* Gemini *********',
        enable_to_support_team: true
    },
    'budget_and_forecast': {
        title: 'Gemini Feature Flag for Budget and Forecast Module',
        help_text: '********* Gemini *********',
        enable_to_support_team: true
    },
    'lens': {
        title: 'Gemini Feature Flag for Lens Allocation',
        help_text: '********* Gemini *********',
        enable_to_support_team: true
    },
    'view_settings': {
        title: 'Gemini Feature Flag for Settings Page',
        help_text: '********* Gemini *********',
        enable_to_support_team: true
    },
    'multi_currency': {
        title: 'Gemini Feature Flag for Multi Currency',
        help_text: '********* Gemini *********',
        enable_to_support_team: true
    },
    'sp_recommendations': {
        title: 'Gemini Feature Flag for Recommendations',
        help_text: '********* Gemini *********',
        enable_to_support_team: true
    },
    'manage_lens': {
        title: 'Gemini Feature Flag for Managing Lens / Edit Screen',
        help_text: '********* Gemini *********',
        enable_to_support_team: true
    }
}