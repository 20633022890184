import axios from 'axios';
// import * as Configs from '../../config';
import * as actions from './index'

import * as actionTypes from './actionTypes';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authReset = () => {
    return {
        type: actionTypes.AUTH_RESET
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const authLogout = (redirect) => {
    // console.log('[authLogout function called]');
    localStorage.removeItem('ys_logged_in_user_id');
    localStorage.removeItem('ys_logged_in_tenant_id');

    return {
        type: actionTypes.AUTH_LOGOUT
    };
};


export const authSuccess = (user_uuid, tenant_uuid) => {
    // console.log('[authSuccess function called]');
    return {
        type: actionTypes.AUTH_SUCCESS,
        user_uuid: user_uuid,
        tenant_uuid: tenant_uuid
    }
};
export const authSuccessReset = (successMsg, token, resetEmail) => {
    // console.log('[authSuccessReset function called]');
    return {
        type: actionTypes.AUTH_SUCCESS,
        successMsg: successMsg,
        token: token,
        resetEmail: resetEmail
    }
};


export const auth = (email, password, redirectUrl) => {
    // console.log('[auth function called]');
    return dispatch => {
        dispatch(authStart());
        const authData = {
            username: email,
            password: password
        };
        axios.post('login', authData, {withCredentials: true})
            .then(response => {
                dispatch(authSuccess());
                dispatch(actions.loggedInUserInfo());
            })
            .catch(err => {
                let msg = 'Something went wrong, please try again later.';
                if (err.response) {
                    switch (err.response.status) {
                        case 401:
                            msg = err.response.data.message;
                            break;
                        case 500:
                            msg = err.response.data;
                            break;
                        default:
                    }
                }
                dispatch(authFail(msg));
            });
    };
};


export const logout = () => {
    // console.log('[logout function called]');
    return dispatch => {
        dispatch(authReset());
        axios.get('logout', {withCredentials: true})
            .then(response => {
                dispatch(authLogout());
                dispatch(actions.loggedInUserReset());
            })
            .catch(err => {
                dispatch(authLogout());
                dispatch(actions.loggedInUserReset());
            });
    };
};


export const forgotPassword = (email) => {
    return dispatch => {
        dispatch(authStart());
        const forgotPasswordData = {
            email: email
        };
        axios.post('user/forgot_password', forgotPasswordData)
            .then(response => {
                dispatch(authSuccessReset("An email with password reset link has been sent to your email address. "))
            })
            .catch(err => {
                const msg = (err.response && err.response.data && err.response.data.message) || 'Something went wrong, please try again later.';
                dispatch(authFail(msg));
            });
    };
};

export const fetchResetPasswordDetails = (token) => {
    return dispatch => {
        dispatch(authReset());
        dispatch(authStart());
        let errorMsg = 'Something went wrong, please try again later.';
        axios.get('user/validate_reset_token/' + token)
            .then(response => {
                if (response.data) {
                    if (response.data.status === 'success') {
                        dispatch(authSuccessReset(null, token, response.data.email));
                    } else {
                        errorMsg = 'Token is invalid, please try with new token';
                        dispatch(authFail(errorMsg));
                    }
                } else {
                    dispatch(authFail(errorMsg));
                }
            })
            .catch(err => {
                const msg = (err.response && err.response.data && err.response.data.message) || errorMsg;
                dispatch(authFail(msg));
            });
    };
};

export const resetPassword = (password, confirmPassword, token) => {
    return dispatch => {
        dispatch(authStart());
        if (password !== confirmPassword) {
            dispatch(authFail('Password and confirm password does not match.'));
            return;
        }
        const resetPassword = {
            token: token,
            new_password: password,
            confirm_password: confirmPassword
        };
        axios.post('user/reset_forgotten_password', resetPassword)
            .then(response => {
                dispatch(authSuccessReset("Password has been changed successfully."))
            })
            .catch(err => {
                const msg = (err.response && err.response.data && err.response.data.message) || (err.response && err.response.data) || 'Something went wrong, please try again later.';
                dispatch(authFail(msg));
            });
    };
};

export const loginWithOrganization = (orgName, redirectUrl) => {
    return dispatch => {
        dispatch(authStart());
        let urlPath = 'auth/' + orgName;
        if (redirectUrl) {
            urlPath += '?redirectionUrl=' + redirectUrl;
        }
        axios.get(urlPath)
            .then(response => {
                let msg = 'Something went wrong, please try again later.';
                if (response.data) {
                    if (response.data.status === "error") {
                        msg = response.data.message;
                        dispatch(authFail(msg));
                    } else if (response.data.status === "success" && response.data.redirect_url !== '') {
                        window.location = response.data.redirect_url;
                    } else {
                        dispatch(authFail(msg));
                    }
                } else {
                    dispatch(authFail(msg));
                }
            })
            .catch(err => {
                const msg = (err.response && err.response.data && err.response.data.message) || 'Something went wrong, please try again later.';
                dispatch(authFail(msg));
            });
    };
};